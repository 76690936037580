export const buttonStyle = {
  height: 45,
  backgroundColor: '#fff',
  borderColor: '#0D6E3A',
  color: '#0D6E3A',
  width: 178,
  fontSize: 14,
}

export const datePickerField = {
  height: 45,
  borderRadius: 30,
  width: '100%',
}
