export const buttonStyle = {
  height: 'auto',
  width: 250,
}

export const customInput = {
  borderRadius: 30,
  marginRight: 20,
  width: 250,
}
