import React, { useState } from 'react'
import { Upload, Modal, message } from 'antd'
import { AiOutlineCamera } from 'react-icons/ai'
import { isEmpty } from 'ramda'

const getBase64 = (file: File) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = error => reject(error)
  })
}

type UploadTypes = {
  action: string
  data: object
  file: File
  filename: string
  headers: object
  onError: Function
  onProgress: Function
  onSuccess: Function
  withCredentials: boolean
}

type FilesTypes = {
  name: string
  status?: string
  uid: string
  url?: string
  preview?: any
  originFileObj?: File
}

type PicturesWallTypes = {
  userEmail: string
  fileList: FilesTypes[]
  handleUpload: Function
  handleDelete: Function
  onFormChange: Function
}

const PicturesWall: React.FC<PicturesWallTypes> = ({
  userEmail,
  fileList,
  handleUpload,
  handleDelete,
  onFormChange,
}) => {
  const [previewVisible, setPreviewVisible] = useState<boolean>(false)
  const [previewImage, setPreviewImage] = useState<string>('')
  const [previewTitle, setPreviewTitle] = useState<string>('')

  const onDelete = async (params: FilesTypes) => {
    const deletion = await handleDelete(params)
    if (deletion) {
      const copy = [...fileList]
      const newItems = copy.filter(item => item.url !== params.url)
      onFormChange(null, 'portfolio', [...newItems])

      message.success('Imagen eliminada')
    } else message.error(`Ocurrió un error al eliminar la imagen`)
  }

  const uploadFile = async (params: UploadTypes) => {
    if (!userEmail || isEmpty(userEmail)) {
      message.error(
        'Para subir imagenes, debes agregar el email del profesional',
      )
      return
    }
    const upload = await handleUpload({ ...params, userEmail })
    if (upload) {
      const copy = [...fileList]
      copy.push(upload)
      onFormChange(null, 'portfolio', [...copy])
      message.success('Imagen del profesional subida con exito')
    } else message.error(`Ocurrió un error al subir la imagen`)
  }

  const handleCancel = () => setPreviewVisible(false)

  const handlePreview = async (file: FilesTypes) => {
    if (!file.url && !file.preview)
      file.preview = await getBase64(file.originFileObj)

    setPreviewImage(file.url || file.preview)
    setPreviewVisible(true)
    setPreviewTitle(
      file.name || file.url.substring(file.url.lastIndexOf('/') + 1),
    )
  }

  const uploadButton = (
    <div>
      <AiOutlineCamera size={30} />
      <div className="ant-upload-text">Agregar imagen</div>
    </div>
  )

  return (
    <div className="clearfix">
      <Upload
        listType="picture-card"
        fileList={fileList}
        onPreview={handlePreview}
        onRemove={onDelete}
        customRequest={uploadFile}>
        {uploadButton}
      </Upload>
      <Modal
        visible={previewVisible}
        title={previewTitle}
        footer={null}
        onCancel={handleCancel}>
        <img alt="example" style={{ width: '100%' }} src={previewImage} />
      </Modal>
    </div>
  )
}

export default PicturesWall
