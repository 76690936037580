import { Facebook, Instagram, Twitter } from './socialNetworks'

export const personalData = [
  {
    label: 'Nombre Apellido',
    name: 'name',
    placeholder: 'Ingresa nombre del profesional ',
  },
  {
    label: 'RUT',
    name: 'rut',
    placeholder: '11.111.111-1 ',
  },
  {
    label: 'Título (Especialidad)',
    name: 'title',
    placeholder: 'Ingresa especialidad del profesional ',
  },
  {
    label: 'Email',
    name: 'email',
    placeholder: 'Ingresa email',
  },
  {
    label: 'Teléfono',
    name: 'phone',
    placeholder: '(+56) 9 xxxx xxxxx',
  },
]

export const socialNetworks = [
  {
    name: 'instagram',
    placeholder: 'Agregar',
    icon: Instagram,
  },
  {
    name: 'facebook',
    placeholder: 'Agregar',
    icon: Facebook,
  },
  {
    name: 'twitter',
    placeholder: 'Agregar',
    icon: Twitter,
  },
]
