import React, { useState, useEffect, Fragment } from 'react'
import styles from './styles.module.css'
import { MdSearch, MdKeyboardArrowDown, MdCancel } from 'react-icons/md'
import { BoxStyled } from './framerStyles'
import { isEmpty, uniq } from 'ramda'
import { Row, Col, Spin } from 'antd'

type SelectedItemTypes = {
  duration: number
  id: string
  price: number
  serviceName: string
  title: string
}

type LocalSpecialtyTypes = {
  specialty: string
  services: {
    value: string
    name: string
    description: string
  }[]
}

type FormTypes = {
  name: string
  rut: string
  title: string
  email: string
  phone: string
  bankData?: {
    bank: string
    accountType: string
    accountNumber: string
  }
  socialNetworks?: {
    facebook: string
    twitter: string
    instagram: string
  }
  portfolio?: {
    name: string
    uid: string
    url: string
    status: string
  }[]
  services: string[]
  specialties: string[]
  picture: {
    name: string
    uid: string
    url: string
    status: string
  }[]
}

type ServiceTypes = {
  id: string
  specialtyId: string
  title: string
  price: number
  duration: number
  details: string
  specialtyName: string
}

type SpecialtyTypes = {
  id: string
  name: string
  description: string
}

type ServiceSelectorProp = {
  specialties: SpecialtyTypes[]
  onFormChange: Function
  form: FormTypes
  onLessSpecialtiesCheck: Function
  servicesLoading: boolean
  servicesError: object
  servicesData: ServiceTypes[]
  initialSelectedItems?: SelectedItemTypes[]
}

const ServiceSelector: React.FC<ServiceSelectorProp> = ({
  specialties,
  onFormChange,
  form,
  onLessSpecialtiesCheck,
  servicesLoading,
  servicesError,
  servicesData,
  initialSelectedItems,
}) => {
  const [allServices, setAllServices] = useState<ServiceTypes[]>([])
  const [prevSpecialties, setPrevSpecialties] = useState<SpecialtyTypes[]>([])
  const [open, setOpen] = useState<boolean>(false)
  const [services, setServices] = useState<LocalSpecialtyTypes[]>([])
  const [selectedItems, setSelectedItems] = useState<SelectedItemTypes[]>([])

  useEffect(() => {
    if (!initialSelectedItems) return
    if (isEmpty(initialSelectedItems)) return
    setSelectedItems([...initialSelectedItems])
    setOpen(true)
  }, [initialSelectedItems])

  useEffect(() => {
    if (servicesLoading) return
    if (servicesError) return
    setAllServices(servicesData)
  }, [servicesData])

  const onLessSpecialtiesCheckLocal = (availableServices: string[]) => {
    const cleanItems = []
    for (const selectedItem of selectedItems)
      if (availableServices.includes(selectedItem.id))
        cleanItems.push({ ...selectedItem })
    setSelectedItems([...cleanItems])
  }

  useEffect(() => {
    const setServicesAvailables = () => {
      if (isEmpty(specialties)) {
        setSelectedItems([])
        setPrevSpecialties([])
        setServices([])
        onFormChange(null, 'specialties', [])
        onFormChange(null, 'services', [])
        setOpen(false)
      } else {
        const lessItems = specialties.length < prevSpecialties.length
        const availableServices: string[] = []
        const newServices = specialties.map(specialty => {
          const thisServices =
            allServices.filter(
              service => service.specialtyId === specialty.id,
            ) || []
          return {
            specialty: specialty.name,
            services: thisServices.map(thisService => {
              if (lessItems) availableServices.push(thisService.id)
              return {
                value: thisService.id,
                name: specialty.name,
                description: thisService.title,
              }
            }),
          }
        })

        if (lessItems) {
          onLessSpecialtiesCheck(availableServices)
          onLessSpecialtiesCheckLocal(availableServices)
        }
        setPrevSpecialties(specialties)
        setServices(newServices)
      }
    }

    setServicesAvailables()
  }, [specialties, allServices])

  const onDeleteSelectedItem = (itemId: string) => {
    if (!itemId) return
    const newServices = selectedItems.filter(item => item.id !== itemId) || []
    const newServicesId =
      form.services.filter(serviceId => serviceId !== itemId) || []
    setSelectedItems([...newServices])
    onFormChange(null, 'services', [...newServicesId])
  }

  const onSelectItem = (itemService: {
    value: string
    name: string
    description: string
  }) => {
    if (!open) return
    if (!itemService?.value) return
    const thisService = allServices.find(
      service => service.id === itemService.value,
    )
    if (!thisService) return
    const newItems = uniq([
      ...selectedItems,
      { ...thisService, serviceName: itemService.name },
    ])
    const newItemsId = uniq([...form.services, itemService.value])
    setSelectedItems([...newItems])
    onFormChange(null, 'services', [...newItemsId])
  }

  const setVisible = () => {
    if (isEmpty(specialties)) return
    setOpen(!open)
  }

  const getItemStyle = (itemId: string) => {
    if (!itemId) return
    if (selectedItems.find(item => item.id === itemId))
      return styles.activeServiceDescription
    else return styles.serviceDescription
  }

  const renderSelectedItems = () => {
    return selectedItems.map((item, idx) => {
      return (
        <div className={styles.card} key={idx.toString()}>
          <div className={styles.cardHeader}>
            <div className={styles.itemSpecialty}>{item.serviceName}</div>
            <div
              className={styles.close}
              onClick={() => onDeleteSelectedItem(item.id)}>
              <MdCancel size={22} color="rgba(255, 255, 255, 0.5)" />
            </div>
          </div>
          <div className={styles.cardDescription}>{item.title}</div>
          <div className={styles.timeCost}>{item.duration} min</div>
          <div className={styles.timeCost}>${item.price}</div>
        </div>
      )
    })
  }

  const renderServices = (
    services: {
      value: string
      name: string
      description: string
    }[],
  ) => {
    return services.map((service, idx: number) => (
      <div
        key={idx.toString()}
        className={getItemStyle(service.value)}
        onClick={() => onSelectItem(service)}>
        {service.description}
      </div>
    ))
  }

  const renderItems = () => {
    return services.map((item: LocalSpecialtyTypes, idx: number) => {
      return (
        <Fragment key={idx.toString()}>
          <div className={styles.specialty}>{item.specialty}</div>
          {renderServices(item.services)}
        </Fragment>
      )
    })
  }

  const renderInput = () => {
    return (
      <div className={styles.input} onClick={() => setVisible()}>
        <MdSearch size={20} color="rgba(34, 31, 31, 0.3)" />
        <div className={styles.inputPlaceholder}>Selecciona los servicios</div>
        <MdKeyboardArrowDown size={20} color="rgba(34, 31, 31, 0.3)" />
      </div>
    )
  }

  const renderSelector = () => {
    return (
      <div className={styles.selector}>
        {renderInput()}
        <BoxStyled animate={open ? 'visible' : 'hidden'}>
          <div className={styles.itemsContent}>{renderItems()}</div>
        </BoxStyled>
      </div>
    )
  }

  if (servicesLoading) return <Spin size="large" />
  return (
    <div className={styles.container}>
      <div className={styles.title}>
        Selecciona el o los servicios que realizará el profesional:
      </div>
      <Row>
        <Col span={12}>{renderSelector()}</Col>
        <Col span={12}>
          <div className={styles.selectedContent}>{renderSelectedItems()}</div>
        </Col>
      </Row>
    </div>
  )
}

export default ServiceSelector
