import React, { useState } from 'react'
import styles from './styles.module.css'
import { BoxStyled } from './framerStyle'
import { categoryButton, createButton } from './customStyles'
import { PlusCircleOutlined } from '@ant-design/icons'
import { Input, message, Button, Select } from 'antd'
import { CREATE_SUBCATEGORY } from '@src/helpers/mutations/subCategories.gql'
import { useMutation } from '@apollo/client'

type FormTypes = {
  specialtyId: string
  name: string
}

type SpecialtyTypes = {
  value: string
  label: string
}

type SubCategoryProps = {
  specialties: SpecialtyTypes[]
  refetchSpecialties: Function
}

interface SubCategoryVars {
  id: string
}

interface SubCategoryInput {
  specialtyId: string
  name: string
}

interface CreateSubCategory {
  input: SubCategoryInput
  createSubCategory: SubCategoryVars
}

const SubCategory: React.FC<SubCategoryProps> = ({
  specialties,
  refetchSpecialties,
}) => {
  const [form, setForm] = useState<FormTypes>({})
  const [open, setOpen] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const [createSubcategory] = useMutation<CreateSubCategory>(CREATE_SUBCATEGORY)

  const onCreateSubCategory = async () => {
    setLoading(true)
    try {
      const response = await createSubcategory({ variables: { input: form } })
      if (!response.data) throw new Error()
      message.success('Subcategoría creada con exrito')
      refetchSpecialties()
      setOpen(false)
      setForm({})
    } catch (error) {
      message.error('Ocurrió un error al crear la subcategoría')
      console.log(error)
    }
    setLoading(false)
  }

  const onInputChange = text => {
    const { value } = text.target
    setForm(prevState => {
      const copy = { ...prevState }
      copy.name = value
      return copy
    })
  }

  const onSelectChange = value => {
    setForm(prevState => {
      const copy = { ...prevState }
      copy.specialtyId = value
      return copy
    })
  }

  const renderCreate = () => (
    <BoxStyled animate={open ? 'visible' : 'hidden'}>
      <div className={styles.input}>
        <div className={styles.label}>Selecciona la especialidad</div>
        <Select
          value={form.specialtyId}
          onChange={onSelectChange}
          placeholder="Selecciona una subcategoría"
          style={{ width: '100%' }}>
          {specialties.map((specialty, idx: number) => {
            return (
              <Option key={idx.toString()} value={specialty.value}>
                {specialty.label}
              </Option>
            )
          })}
        </Select>
      </div>
      <div className={styles.input}>
        <div className={styles.label}>Ingresa el nombre de la subcategoría</div>
        <Input
          style={{ borderRadius: 30, height: 50 }}
          value={form.name}
          placeholder="subcategoría"
          onChange={onInputChange}
        />
      </div>
      <div className={styles.input}>
        <Button
          type="primary"
          onClick={() => onCreateSubCategory()}
          loading={loading}
          icon={<PlusCircleOutlined size={30} />}
          style={{ ...createButton }}>
          Crear
        </Button>
      </div>
    </BoxStyled>
  )

  return (
    <div className={styles.container}>
      <Button
        onClick={() => setOpen(!open)}
        icon={<PlusCircleOutlined size={30} />}
        style={{ ...categoryButton }}>
        Crear subcategoría
      </Button>
      {renderCreate()}
    </div>
  )
}

export default SubCategory
