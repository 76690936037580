import React, { useState, useEffect } from 'react'
import styles from './styles.module.css'
import { MdCancel } from 'react-icons/md'
import { Spin } from 'antd'

type SpecialtiesProps = {
  allSpecialties: {
    id: string
    name: string
    description: string
  }[]
  specialtiesError: object
  specialtiesLoading: boolean
  onSelectSpecialty: Function
  selectedItems: {
    id: string
    name: string
    description: string
  }[]
}

type SpecialtyTypes = {
  id: string
  name: string
  description: string
}

const Specialties: React.FC<SpecialtiesProps> = ({
  allSpecialties,
  specialtiesError,
  specialtiesLoading,
  onSelectSpecialty,
  selectedItems,
}) => {
  const [specialties, setSpecialties] = useState<SpecialtyTypes[]>([])

  useEffect(() => {
    if (specialtiesLoading || specialtiesError) return
    setSpecialties(allSpecialties)
  }, [allSpecialties])

  const isActiveItem = (itemId: string) =>
    selectedItems.find(item => item.id === itemId)

  const renderSpecialty = (spcialty: SpecialtyTypes, idx: number) => {
    const isActive = isActiveItem(spcialty.id)
    return (
      <div
        key={idx.toString()}
        className={isActive ? styles.activeSpecialty : styles.specialty}
        onClick={() => onSelectSpecialty(spcialty)}>
        <div className={styles.label}>{spcialty.name}</div>
        {isActive && <MdCancel size={25} color="rgba(255, 255, 255, 0.5)" />}
      </div>
    )
  }

  const renderItems = () => {
    return (
      <div className={styles.items}>
        {specialties.map((spcialty, idx) => {
          return renderSpecialty(spcialty, idx)
        })}
      </div>
    )
  }

  const renderTitle = () => {
    return (
      <div className={styles.title}>
        Selecciona la especializacion del profesional:
      </div>
    )
  }

  if (specialtiesLoading) return <Spin size="large" />
  return (
    <div className={styles.container}>
      {renderTitle()}
      {renderItems()}
    </div>
  )
}

export default Specialties
