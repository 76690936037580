import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'

type InstagramTypes = {
  className: object
}

const Instagram: React.FC<InstagramTypes> = ({ className }) => {
  const data = useStaticQuery(graphql`
    query {
      instagram: file(relativePath: { eq: "instagram.png" }) {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <Img className={className} fluid={data.instagram.childImageSharp.fluid} />
  )
}

export default Instagram
