import React, { useState, useEffect } from 'react'
import styles from './styles.module.css'
import { PROFESSIONAL_MEETINGS } from '@src/helpers/queries/meetings.gql'
import { MeetingsData } from './types'
import Loading from '@src/components/loading'
import { useQuery } from '@apollo/client'
import { header } from './data'
import { Table } from 'antd'
import Logo from './logo'
import dayjs from 'dayjs'

require('dayjs/locale/es')

type MeetingsTypes = {
  key: number
  id: string
  services: string[]
  client: string
  review: string
  rating: number
  date: string
}[]

type ReviewsProps = {
  professionalId: string
}

const Reviews: React.FC<ReviewsProps> = ({ professionalId }) => {
  const [items, setItems] = useState<MeetingsTypes>([])
  const { loading, error, data } = useQuery<MeetingsData>(
    PROFESSIONAL_MEETINGS,
    {
      variables: { professionalId },
      skip: !professionalId,
    },
  )

  useEffect(() => {
    if (data && data.allProfessionalMeetings) {
      console.log(data.allProfessionalMeetings)
      const newItems = data.allProfessionalMeetings.map((meeting, idx) => {
        return {
          key: idx,
          id: meeting.id,
          services: meeting.serviceDetails.map(service => service.serviceName),
          client: meeting.user?.auth0Data
            ? meeting.user.auth0Data.name
              ? `${meeting.user?.auth0Data.name} <${meeting.user?.auth0Data.email}>`
              : meeting.user?.auth0Data.email
            : '',
          review: meeting.review,
          rating: meeting.rating,
          date: dayjs(meeting.metadata.date)
            .locale('es')
            .format('DD MMMM YYYY'),
        }
      })
      setItems(newItems)
    }
  }, [data])

  if (loading) return <Loading />
  if (error) return <span>Error...</span>
  return (
    <div className={styles.container}>
      <Logo className={styles.logo} />
      <div className={styles.table}>
        <Table columns={header} dataSource={items} />
      </div>
    </div>
  )
}

export default Reviews
