import React from 'react'
import styles from './styles.module.css'
import { navigate } from 'gatsby'
import { Button } from 'antd'
import { buttonStyle } from './customStyle'
import BackArrow from '@src/components/back-arrow'
import { Facebook, Twitter, Instagram } from './socialNetworks'
import TitleHeader from '@src/components/titleHeader'
import { ProfessionalProfileProps } from './types'
import { FaRegEdit } from 'react-icons/fa'
import Review from './reviews'

const Main: React.FC<ProfessionalProfileProps> = ({ professionalData }) => {
  const renderServices = () => {
    const { services } = professionalData
    return (
      <div className={styles.services}>
        <div className={styles.title}>Servicios</div>
        <div className={styles.servicesGroup}>
          {services.map((service, idx: number) => (
            <div className={styles.service} key={idx.toString()}>
              <div className={styles.title}>{service.title}</div>
              <div className={styles.specialtyLittle}>{service.type}</div>
              <div className={styles.serviceDescription}>
                {service.description}
              </div>
              <div className={styles.duration}>{service.duration} minutos</div>
              <div className={styles.price}>${service.price}</div>
            </div>
          ))}
        </div>
      </div>
    )
  }

  const renderSpecialties = () => {
    const { specialties } = professionalData
    return (
      <div className={styles.specialties}>
        <div className={styles.title}>
          Selecciona la especializacion del profesional
        </div>
        <div className={styles.specialtiesGroup}>
          {specialties.map((specialty, idx: number) => (
            <div key={idx.toString()} className={styles.specialty}>
              {specialty}
            </div>
          ))}
        </div>
      </div>
    )
  }

  const renderPortfolio = () => {
    const { portfolio } = professionalData
    return (
      <div className={styles.porfolio}>
        <div className={styles.title}>Portafolio</div>
        <div className={styles.portfolioImages}>
          {portfolio.map((image, idx) => (
            <img
              key={idx.toString()}
              className={styles.portfolioImage}
              src={image}
              alt={Math.random().toString()}
            />
          ))}
        </div>
      </div>
    )
  }

  const renderSocialNetwork = (user: string, logo: Function) => {
    if (!user) return <span />
    const Component = logo
    return (
      <div className={styles.socialNetwork}>
        <Component className={styles.networkStyles} />
        <div className={styles.networkUser}>
          <b>@</b>
          {user}
        </div>
      </div>
    )
  }

  const renderSocialNetworks = () => {
    const { socialNetwork } = professionalData
    return (
      <div className={styles.networks}>
        <div className={styles.title}>Redes sociales</div>
        <div className={styles.socialNetworks}>
          {renderSocialNetwork(socialNetwork.facebook, Facebook)}
          {renderSocialNetwork(socialNetwork.twitter, Twitter)}
          {renderSocialNetwork(socialNetwork.instagram, Instagram)}
        </div>
      </div>
    )
  }

  const renderData = (data: string) => (
    <div className={styles.individualData}>{data}</div>
  )

  const renderBankData = () => {
    const { bank } = professionalData
    return (
      <div className={styles.bank}>
        <div className={styles.title}>Datos Bancarios</div>
        <div>
          {renderData(bank.name)}
          {renderData(bank.type)}
          {renderData(bank.value)}
        </div>
      </div>
    )
  }

  const renderPersonalData = () => {
    const { personalData } = professionalData
    return (
      <div className={styles.personal}>
        <div className={styles.title}>Datos personales</div>
        <div className={styles.individualGroup}>
          {renderData(personalData.name)}
          {renderData(personalData.phone)}
          {renderData(personalData.rut)}
          {renderData(personalData.email)}
          {renderData(personalData.specialty)}
        </div>
      </div>
    )
  }

  const renderProfessionalInfo = () => {
    return (
      <div className={styles.personalData}>
        {renderPersonalData()}
        {renderBankData()}
      </div>
    )
  }

  const renderProfessionalCard = () => {
    return (
      <div className={styles.professional}>
        <img
          className={styles.professionalPic}
          src={professionalData.picture}
          alt={Math.random().toString()}
        />
        <div className={styles.data}>
          <div className={styles.name}>{professionalData.name}</div>
          <div className={styles.title}>{professionalData.title}</div>
        </div>
      </div>
    )
  }

  const renderTitle = () => {
    return (
      <div className={styles.header}>
        <TitleHeader label="perfil profesional" />
        <Button
          onClick={() =>
            navigate(`/professionals/${professionalData.id}/update`)
          }
          style={{ ...buttonStyle }}
          icon={<FaRegEdit size={20} />}>
          Editar profesional
        </Button>
      </div>
    )
  }

  return (
    <div className={styles.container}>
      <BackArrow path="/professionals" />
      {renderTitle()}
      {renderProfessionalCard()}
      {renderProfessionalInfo()}
      {renderSocialNetworks()}
      {renderPortfolio()}
      {renderSpecialties()}
      {renderServices()}
      <Review professionalId={professionalData.id} />
    </div>
  )
}

export default Main
