import numeral from 'numeral'

export default (text: string) => {
  if (text.length < 3) return text
  const numbers = text.replace(/[^\dkK]/g, '')
  const parts = numbers.split('')
  if (parts.length === 0) return numbers

  const last = parts.pop()
  if (parts.length === 0) return numbers

  let final = `${numeral(parts.join('')).format('0,0')}-${last}`
  final = final.replace(/,/g, '.')
  return final
}
