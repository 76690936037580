export default [
  {
    name: 'title',
    label: 'Nombre',
    placeholder: 'Ingresa nombre servicio',
  },
  {
    name: 'price',
    label: 'Precio',
    placeholder: 'Precio servicio',
  },
  {
    name: 'duration',
    label: 'Tiempo (En minutos)',
    placeholder: 'Tiempo',
  },
]

export const subCategories = {
  'specialty-id-1': [
    {
      label: 'asds',
      value: 'asdasd',
    },
  ],
  'specialty-id-2': [
    {
      label: 'asds',
      value: 'asdasd',
    },
  ],
  'specialty-id-3': [
    {
      label: 'asds',
      value: 'asdasd',
    },
  ],
  'specialty-id-4': [
    {
      label: 'asds',
      value: 'asdasd',
    },
  ],
}
