function click(node: any, target: any) {
  try {
    node.dispatchEvent(new MouseEvent('click'))
  } catch (e) {
    var evt = document.createEvent('MouseEvents')
    evt.initMouseEvent(
      'click',
      true,
      true,
      window,
      0,
      0,
      0,
      80,
      20,
      false,
      false,
      false,
      false,
      0,
      null,
    )
    node.dispatchEvent(evt)
  }
}

async function saveAs(blob: any, name: string, _global: any) {
  const URL = _global.URL || _global.webkitURL
  const a = document.createElement('a')
  name = name || blob.name || 'download'
  a.download = name
  a.rel = 'noopener'

  let buf = new ArrayBuffer(blob.length) //convert s to arrayBuffer
  let view = new Uint8Array(buf) //create uint8array as viewer
  for (var i = 0; i < blob.length; i++) view[i] = blob.charCodeAt(i) & 0xff //convert to octet

  a.href = URL.createObjectURL(
    new Blob([buf], { type: 'application/octet-stream' }),
  )
  click(a, (a.target = '_blank'))
}

export default async (file, fileName, downloadProgress) => {
  const _global =
    typeof window === 'object' && window.window === window
      ? window
      : typeof self === 'object' && self.self === self
      ? self
      : typeof global === 'object' && global.global === global
      ? global
      : this

  saveAs(file, fileName, _global)
}
