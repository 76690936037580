export const banks = [
  {
    label: 'Banco de Chile - Edwards',
    value: 'banco-de-chile-edwards',
  },
  {
    label: 'Banco de BICE',
    value: 'banco-bice',
  },
  {
    label: 'Banco de Consorcio',
    value: 'banco-consorcio',
  },
  {
    label: 'Banco del Estado de Chile',
    value: 'banco-del-estado-de-chile',
  },
  {
    label: 'Banco do Brasil S.A.',
    value: 'banco-do-brazil-sa',
  },
  {
    label: 'Banco Falabella',
    value: 'banco-falabella',
  },
  {
    label: 'Banco Internacional',
    value: 'banco-internacional',
  },
  {
    label: 'Banco Paris',
    value: 'banco-paris',
  },
  {
    label: 'Banco Ripley',
    value: 'banco-ripley',
  },
  {
    label: 'Banco Santander',
    value: 'banco-santander',
  },
  {
    label: 'Banco Security',
    value: 'banco-security',
  },
  {
    label: 'BBVA',
    value: 'bbva',
  },
  {
    label: 'BCI',
    value: 'bci',
  },
  {
    label: 'Coopeuch',
    value: 'coopeuch',
  },
  {
    label: 'HSBC Bank',
    value: 'hsbc-bank',
  },
  {
    label: 'Banco Itaú Chile',
    value: 'banco-itau-chile',
  },
  {
    label: 'Banco Itaú Corpbanca',
    value: 'banco-itau-corpbanca',
  },
  {
    label: 'Scotiabank',
    value: 'scotiabank',
  },
]

export const accounts = [
  {
    label: 'Cuenta corriente',
    value: 'cuenta-corriente',
  },
  {
    label: 'Cuenta vista',
    value: 'cuenta-vista',
  },
]
