import React, { useState, useEffect } from 'react'
import { useQuery } from '@apollo/client'
import { PROFESSIONAL } from '@src/helpers/queries/professionals.gql'
import { ProfessionalInventory, ProfessionalInventoryVars } from './queryTypes'
import { ProfessionalTypes, ProfessionalProfileProps } from './types'
import Loading from '@src/components/loading'
import parseData from './parseData'
import { isEmpty } from 'ramda'
import Main from './main'

const ProfessionalProfile: React.FC<ProfessionalProfileProps> = ({
  professionalId,
}) => {
  const [professionalData, setProfessionalData] = useState<ProfessionalTypes>(
    {},
  )
  const { loading, error, data } = useQuery<
    ProfessionalInventory,
    ProfessionalInventoryVars
  >(PROFESSIONAL, {
    variables: { id: professionalId },
    skip: !professionalId,
  })

  useEffect(() => {
    if (data && data.Professional) {
      const parsedData = parseData(data.Professional)
      setProfessionalData({ ...parsedData })
    }
  }, [data])

  if (loading || isEmpty(professionalData)) return <Loading />
  return <Main professionalData={professionalData} />
}

export default ProfessionalProfile
