import React, { useState, useCallback } from 'react'
import { useMutation, useQuery } from '@apollo/client'
import styles from './styles.module.css'
import PersonalData from '../components/personal-data'
import ServiceSelector from '../components/service-selector'
import { USER } from '@src/helpers/localQueries/userQuery'
import CreateService from '../components/create-service'
import Specialties from '../components/specialties'
import TitleHeader from '@src/components/titleHeader'
import BackArrow from '@src/components/back-arrow'
import Loading from '@src/components/loading'
import { buttonStyle } from './customStyles'
import { Button, message } from 'antd'
import { intersection } from 'ramda'
import { navigate } from 'gatsby'
import {
  DeleteFileInterface,
  CreateProfessionalInterface,
} from './mutationTypes'
import axios from 'axios'
import apiUrl from '@src/helpers/apiUrl'
import { UPLOAD_FILE, DELETE_FILE } from '@src/helpers/mutations/files.gql'
import { CREATE_PROFESSIONAL } from '@src/helpers/mutations/professionals.gql'
import { ALL_SPECIALTIES } from '@src/helpers/queries/specialties.gql'
import { ALL_SERVICES } from '@src/helpers/queries/services.gql'

type SpecialtyTypes = {
  id: string
  name: string
  description: string
}

type FormTypes = {
  name: string
  rut: string
  title: string
  email: string
  phone: string
  bankData?: {
    bank: string
    accountType: string
    accountNumber: string
  }
  socialNetworks?: {
    facebook: string
    twitter: string
    instagram: string
  }
  portfolio?: {
    name: string
    uid: string
    url: string
    status: string
  }[]
  services: string[]
  specialties: string[]
  picture: {
    name: string
    uid: string
    url: string
    status: string
  }[]
}

interface Services {
  id: string
  specialtyId: string
  title: string
  price: number
  duration: number
  details: string
  specialtyName: string
}

interface ServicesInventory {
  allServices: Services[]
}

interface AllSpecialties {
  id: string
  name: string
  description: string
}

interface SpecialtiesIntentory {
  allSpecialties: AllSpecialties[]
}

const Create: React.FC = () => {
  const [deleteFile] = useMutation<DeleteFileInterface>(DELETE_FILE)
  const [createProfessional] = useMutation<CreateProfessionalInterface>(
    CREATE_PROFESSIONAL,
  )
  const { data: localData } = useQuery(USER)
  const {
    loading: specialtiesLoading,
    error: specialtiesError,
    data: allSpecialties,
  } = useQuery<SpecialtiesIntentory>(ALL_SPECIALTIES)
  const {
    loading: servicesLoading,
    error: servicesError,
    data: servicesData,
    refetch: servicesRefetch,
  } = useQuery<ServicesInventory>(ALL_SERVICES)
  const [specialties, setSpecialties] = useState<SpecialtyTypes[]>([])
  const [createLoading, setCreateLoading] = useState<boolean>(false)
  const [form, setForm] = useState<FormTypes>({
    bankData: {},
    socialNetworks: {},
    portfolio: [],
    services: [],
    specialties: [],
    picture: [],
    backgroundImage: [],
  })

  const onCreateProfessional = async () => {
    setCreateLoading(true)
    try {
      const { data } = await createProfessional({
        variables: { input: form, token: localData?.user?.accessToken },
      })
      if (!data) throw new Error('null response from server')
      message.success('Profesional creado con exito')
      navigate(`/professionals/${data.createProfessional.id}`)
    } catch (error) {
      message.error(`Error al crear profesional`)
      console.log(error)
    }
    setCreateLoading(false)
  }

  const handleDelete = async (params: { url: string }) => {
    try {
      const response = await deleteFile({
        variables: { fileUrl: params.url },
      })
      return response.data.removeFile
    } catch (error) {
      console.log(error)
    }
  }

  const handleUpload = async (params: {
    userEmail: string
    file: {
      name: string
      uid: string
    }
    onError: Function
  }) => {
    const { file, onError, userEmail } = params
    const formData = new FormData()
    formData.append('file', file)
    formData.append('userEmail', userEmail)

    let config = {
      headers: {
        'Content-Type': undefined,
      },
    }

    try {
      const upload = await axios.post(
        `${apiUrl()}/files/uploadFile`,
        formData,
        config,
      )

      if (upload?.data?.url)
        return {
          name: params.file.name,
          uid: params.file.uid,
          url: upload.data?.url,
          status: 'done',
        }
    } catch (error) {
      console.log(error)
      onError(error)
    }
  }

  const onLessSpecialtiesCheck = (availableSpecialties: string[]) => {
    setForm((prevState: FormTypes) => {
      const formCopy = { ...prevState }
      formCopy.services = intersection(formCopy.services, availableSpecialties)
      return { ...formCopy }
    })
  }

  const onSelectSpecialty = (specialty: SpecialtyTypes) => {
    const exists = specialties.find(
      thisSpecialty => thisSpecialty.id === specialty.id,
    )
    if (exists) {
      const newItems =
        specialties.filter(
          thisSpecialty => thisSpecialty.id !== specialty.id,
        ) || []
      setSpecialties([...newItems])
      setForm((prevState: FormTypes) => {
        const formCopy = { ...prevState }
        formCopy.specialties = newItems.map(item => item.id)
        return { ...formCopy }
      })
    } else {
      setSpecialties((prevState: SpecialtyTypes[]) => {
        const copy = [...prevState]
        copy.push({ ...specialty })
        return [...copy]
      })
      setForm((prevState: FormTypes) => {
        const formCopy = { ...prevState }
        formCopy.specialties.push(specialty.id)
        return { ...formCopy }
      })
    }
  }

  const onFormChange = useCallback(
    (type: string, name: string, value: string) => {
      setForm((prevState: FormTypes) => {
        const formCopy = { ...prevState }
        if (type) formCopy[type][name] = value
        else formCopy[name] = value
        return formCopy
      })
    },
    [],
  )

  if (specialtiesLoading) return <Loading />
  if (servicesLoading) return <Loading />
  return (
    <div className={styles.container}>
      <BackArrow path="/professionals" />
      <TitleHeader label="nuevo profesional" />
      <PersonalData
        form={{ ...form }}
        onFormChange={onFormChange}
        handleUpload={handleUpload}
        handleDelete={handleDelete}
      />
      <CreateService
        specialtiesLoading={specialtiesLoading}
        specialtiesError={specialtiesError}
        allSpecialties={allSpecialties && allSpecialties.allSpecialties}
        servicesRefetch={servicesRefetch}
      />
      <Specialties
        specialtiesLoading={specialtiesLoading}
        specialtiesError={specialtiesError}
        allSpecialties={allSpecialties && allSpecialties.allSpecialties}
        onSelectSpecialty={onSelectSpecialty}
        selectedItems={[...specialties]}
      />
      <ServiceSelector
        specialties={specialties}
        onFormChange={onFormChange}
        onLessSpecialtiesCheck={onLessSpecialtiesCheck}
        servicesLoading={servicesLoading}
        servicesError={servicesError}
        servicesData={servicesData && servicesData.allServices}
        form={{ ...form }}
      />
      <div className={styles.save}>
        <Button
          style={{ ...buttonStyle }}
          loading={createLoading}
          onClick={() => onCreateProfessional()}>
          CREAR PROFESSIONAL
        </Button>
      </div>
    </div>
  )
}

export default Create
