import * as React from 'react'
import rutParser from '@src/helpers/rutParser'
import numeral from 'numeral'

type MeetingsTypes = {
  key: string
  id: string
  serviceName: string
  client: string
  price: number
  date: Date
}

export const header = [
  {
    title: 'Fecha',
    dataIndex: 'date',
    key: 'date',
    render: (text: string, record: MeetingsTypes) => {
      const style =
        record.key % 2
          ? { background: '#fff', color: '#221F1F' }
          : { background: 'rgba(245, 213, 242, 0.4)', color: '#221F1F' }
      return {
        props: {
          style,
        },
        children: <div style={{ fontSize: 15 }}>{text}</div>,
      }
    },
  },
  {
    title: 'Servicio',
    dataIndex: 'serviceName',
    key: 'serviceName',
    render: (text: string, record: MeetingsTypes) => {
      const style =
        record.key % 2
          ? { background: '#fff', color: '#221F1F' }
          : { background: 'rgba(245, 213, 242, 0.4)', color: '#221F1F' }
      return {
        props: {
          style,
        },
        children: <div style={{ fontSize: 15 }}>{text}</div>,
      }
    },
  },
  {
    title: 'Costo',
    dataIndex: 'price',
    key: 'price',
    render: (text: string, record: MeetingsTypes) => {
      const style =
        record.key % 2
          ? { background: '#fff', color: '#221F1F' }
          : { background: 'rgba(245, 213, 242, 0.4)', color: '#221F1F' }
      return {
        props: {
          style,
        },
        children: (
          <div style={{ fontSize: 15 }}>{numeral(text).format('$ 0,0')}</div>
        ),
      }
    },
  },
  {
    title: 'Cliente',
    dataIndex: 'client',
    key: 'client',
    render: (text: string, record: MeetingsTypes) => {
      const style =
        record.key % 2
          ? { background: '#fff', color: '#221F1F' }
          : { background: 'rgba(245, 213, 242, 0.4)', color: '#221F1F' }
      return {
        props: {
          style,
        },
        children: <div style={{ fontSize: 15 }}>{text}</div>,
      }
    },
  },
]
