type DatesSelectedTypes = {
  dateFrom?: Date
  dateTo?: Date
}

export const queryFilter = (
  datesSelected: DatesSelectedTypes,
  dateFilter: { dateFrom?: Date; dateTo?: Date },
) => {
  const { dateFrom, dateTo } = dateFilter

  if (!dateFrom) return
  if (datesSelected.dateFrom && dateFrom === datesSelected.dateFrom && !dateTo)
    return
  if (
    datesSelected.dateFrom &&
    dateFrom === datesSelected.dateFrom &&
    dateTo &&
    datesSelected.dateTo &&
    dateTo === datesSelected.dateTo
  )
    return

  return true
}
