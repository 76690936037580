import slugify from '@src/helpers/slugify'

type LocalUsersTypes = {
  id: string
  key: number
  name: string
  rut: string
  email: string
  phone: string
}

export default (text: string, items: LocalUsersTypes[]) => {
  const toFind = slugify(text)
  const newItems = items.filter(
    item =>
      slugify(item.name).includes(toFind) ||
      slugify(item.rut).includes(toFind) ||
      slugify(item.email).includes(toFind) ||
      slugify(item.phone).includes(toFind),
  )

  if (!newItems) return []
  return newItems
}
