import React, { useState, useEffect, useCallback } from 'react'
import { useMutation, useQuery } from '@apollo/client'
import styles from './styles.module.css'
import { professionalInformation, professionalServices } from './parseData'
import PersonalData from '../components/personal-data'
import ServiceSelector from '../components/service-selector'
import CreateService from '../components/create-service'
import TitleHeader from '@src/components/titleHeader'
import Specialties from '../components/specialties'
import { intersection, isEmpty } from 'ramda'
import { Button, message } from 'antd'
import Loading from '@src/components/loading'
import { buttonStyle } from './customStyles'
import BackArrow from '@src/components/back-arrow'
import { navigate } from 'gatsby'
import Delete from './delete'
import {
  ProfessionalInventoryVars,
  ProfessionalInventory,
  ServicesInventory,
  SpecialtiesIntentory,
} from './queryTypes'
import {
  DeleteFileInterface,
  UpdateProfessionalInterface,
} from './mutationTypes'
import {
  SpecialtyTypes,
  FormTypes,
  SelectedServicesTypes,
  UpdateProps,
} from './types'
import axios from 'axios'
import apiUrl from '@src/helpers/apiUrl'
import { PROFESSIONAL } from '@src/helpers/queries/professionals.gql'
import { DELETE_FILE } from '@src/helpers/mutations/files.gql'
import { UPDATE_PROFESSIONAL } from '@src/helpers/mutations/professionals.gql'
import { ALL_SPECIALTIES } from '@src/helpers/queries/specialties.gql'
import { ALL_SERVICES } from '@src/helpers/queries/services.gql'

const Update: React.FC<UpdateProps> = ({ professionalId }) => {
  const [specialties, setSpecialties] = useState<SpecialtyTypes[]>([])
  const [createLoading, setCreateLoading] = useState<boolean>(false)
  const [initialSelectedServices, setInitialSelectedServices] = useState<
    SelectedServicesTypes[]
  >([])
  const [form, setForm] = useState<FormTypes>({})
  const {
    loading: specialtiesLoading,
    error: specialtiesError,
    data: specialtiesData,
  } = useQuery<SpecialtiesIntentory>(ALL_SPECIALTIES)
  const {
    loading: servicesLoading,
    error: servicesError,
    data: servicesData,
    refetch: servicesRefetch,
  } = useQuery<ServicesInventory>(ALL_SERVICES)
  const {
    loading: professionalLoading,
    error: professionalError,
    data: professionalData,
  } = useQuery<ProfessionalInventory, ProfessionalInventoryVars>(PROFESSIONAL, {
    variables: { id: professionalId },
    skip: !professionalId,
    notifyOnNetworkStatusChange: true,
  })

  const [deleteFile] = useMutation<DeleteFileInterface>(DELETE_FILE)
  const [updateProfessional] = useMutation<UpdateProfessionalInterface>(
    UPDATE_PROFESSIONAL,
  )

  useEffect(() => {
    if (professionalData && professionalData.Professional) {
      const parsedData = professionalInformation({
        ...professionalData.Professional,
      })
      const activeServices = professionalServices({
        ...professionalData.Professional,
      })
      setForm({ ...parsedData })
      setInitialSelectedServices([...activeServices])
    }
  }, [professionalData])

  useEffect(() => {
    if (
      professionalLoading ||
      professionalError ||
      !professionalData ||
      specialtiesLoading ||
      specialtiesError ||
      !specialtiesData
    )
      return

    const selectedItems = specialtiesData.allSpecialties.filter(
      (specialty: SpecialtyTypes) => {
        return professionalData.Professional.specialties.includes(specialty.id)
      },
    )

    if (isEmpty(selectedItems)) return
    setSpecialties([...selectedItems])
  }, [professionalData, specialtiesData])

  const onUpdateProfessional = async () => {
    setCreateLoading(true)
    try {
      const response = await updateProfessional({
        variables: { id: professionalId, input: form },
      })
      message.success('Profesional actualizado con exito')
      navigate(`/professionals/${professionalId}`)
    } catch (error) {
      message.error(`Error al actualizar profesional`)
      console.log(error)
    }
    setCreateLoading(false)
  }

  const handleDelete = async (params: { url: string }) => {
    try {
      const response = await deleteFile({
        variables: { fileUrl: params.url },
      })
      return response.data.removeFile
    } catch (error) {
      console.log(error)
    }
  }

  const handleUpload = async (params: {
    userEmail: string
    file: {
      name: string
      uid: string
    }
    onError: Function
  }) => {
    const { file, onError, userEmail } = params
    const formData = new FormData()
    formData.append('file', file)
    formData.append('userEmail', userEmail)

    let config = {
      headers: {
        'Content-Type': undefined,
      },
    }

    try {
      const upload = await axios.post(
        `${apiUrl()}/files/uploadFile`,
        formData,
        config,
      )

      if (upload?.data?.url)
        return {
          name: params.file.name,
          uid: params.file.uid,
          url: upload.data?.url,
          status: 'done',
        }
    } catch (error) {
      console.log(error)
      onError(error)
    }
  }

  const onLessSpecialtiesCheck = (availableSpecialties: string[]) => {
    setForm(prevState => {
      const formCopy = { ...prevState }
      formCopy.services = intersection(formCopy.services, availableSpecialties)
      return { ...formCopy }
    })
  }

  const onSelectSpecialty = (specialty: SpecialtyTypes) => {
    const exists = specialties.find(
      thisSpecialty => thisSpecialty.id === specialty.id,
    )
    if (exists) {
      const newItems =
        specialties.filter(
          thisSpecialty => thisSpecialty.id !== specialty.id,
        ) || []
      setSpecialties([...newItems])
      setForm(prevState => {
        const formCopy = { ...prevState }
        formCopy.specialties = newItems.map(item => item.id)
        return { ...formCopy }
      })
    } else {
      setSpecialties(prevState => {
        const copy = [...prevState]
        copy.push({ ...specialty })
        return [...copy]
      })
      setForm(prevState => {
        const formCopy = { ...prevState }
        formCopy.specialties.push(specialty.id)
        return { ...formCopy }
      })
    }
  }

  const onFormChange = useCallback(
    (type: string, name: string, value: string) => {
      setForm(prevState => {
        const formCopy = { ...prevState }
        if (type) formCopy[type][name] = value
        else formCopy[name] = value
        return formCopy
      })
    },
    [],
  )

  if (
    professionalLoading ||
    specialtiesLoading ||
    servicesLoading ||
    isEmpty(form)
  )
    return <Loading />
  return (
    <div className={styles.container}>
      <BackArrow path={`/professionals/${professionalId}`} />
      <TitleHeader label="editar profesional" />
      <PersonalData
        form={{ ...form }}
        onFormChange={onFormChange}
        handleUpload={handleUpload}
        handleDelete={handleDelete}
      />
      <CreateService
        specialtiesLoading={specialtiesLoading}
        specialtiesError={specialtiesError}
        allSpecialties={specialtiesData.allSpecialties}
        servicesRefetch={servicesRefetch}
      />
      <Specialties
        specialtiesLoading={specialtiesLoading}
        specialtiesError={specialtiesError}
        allSpecialties={specialtiesData.allSpecialties}
        onSelectSpecialty={onSelectSpecialty}
        selectedItems={[...specialties]}
      />
      <ServiceSelector
        specialties={specialties}
        onFormChange={onFormChange}
        onLessSpecialtiesCheck={onLessSpecialtiesCheck}
        servicesLoading={servicesLoading}
        servicesError={servicesError}
        servicesData={servicesData.allServices}
        initialSelectedItems={initialSelectedServices}
        form={{ ...form }}
      />
      <div className={styles.save}>
        <Button
          style={{ ...buttonStyle }}
          loading={createLoading}
          onClick={onUpdateProfessional}>
          GUARDAR CAMBIOS
        </Button>
        <Delete professionalId={professionalId} />
      </div>
    </div>
  )
}

export default Update
