export const buttonStyle = {
  marginLeft: 10,
  borderRadius: 30,
  color: '#FF5050',
  borderColor: '#FF5050',
  height: 50,
  width: 330,
}

export const confirMationButtonStyle = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: 30,
  color: '#fff',
  borderColor: '#F63441',
  backgroundColor: '#F63441',
  height: 30,
  width: 115,
}
