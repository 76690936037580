import React, { useState, useEffect } from 'react'
import styles from './styles.module.css'
import { Table, Button, Input } from 'antd'
import { SearchOutlined, PlusCircleOutlined } from '@ant-design/icons'
import TitleHeader from '@src/components/titleHeader'
import Loading from '@src/components/loading'
import { useQuery } from '@apollo/client'
import searchFilter from './searchFilter'
import { navigate } from 'gatsby'
import { header } from './data'
import { isEmpty } from 'ramda'
import { buttonStyle, customInput } from './customStyles'
import { ALL_SERVICES } from '@src/helpers/queries/services.gql'

interface ServicesTypes {
  id: string
  specialtyId: string
  title: string
  price: number
  duration: number
  details: string
  specialtyName: string
}

interface ServicesInventory {
  allServices: ServicesTypes[]
}

const Services: React.FC = () => {
  const [filter, setFilter] = useState<string>(null)
  const [items, setItems] = useState<ServicesTypes[]>([])
  const [services, setServices] = useState<ServicesTypes[]>([])
  const { loading, error, data } = useQuery<ServicesInventory>(ALL_SERVICES, {
    notifyOnNetworkStatusChange: true,
  })

  const goToCreate = () => {
    navigate('/services/create')
  }

  useEffect(() => {
    const addServices = () => {
      if (data && data.allServices) {
        const parseData = data.allServices.map((service, idx: number) => {
          return {
            key: idx,
            ...service,
          }
        })
        setServices(parseData)
      }
    }

    addServices()
  }, [data])

  useEffect(() => {
    const setTableItems = () => {
      if (!filter) setItems(services)
      else {
        const newItems = searchFilter(filter, services)
        setItems(newItems)
      }
    }

    setTableItems()
  }, [filter, services])

  const onTextChange = (text: { target: { value: string } }) => {
    if (isEmpty(text.target.value)) setFilter(null)
    else setFilter(text.target.value)
  }

  const renderHeader = () => {
    return (
      <div className={styles.header}>
        <div className={styles.logoContent}>
          <TitleHeader label="servicios" />
        </div>
        <div className={styles.inputs}>
          <Input
            style={{ ...customInput }}
            placeholder="Buscar"
            prefix={<SearchOutlined />}
            onChange={onTextChange}
          />
          <Button
            onClick={goToCreate}
            style={{ ...buttonStyle }}
            type="primary"
            shape="round"
            icon={<PlusCircleOutlined />}>
            Agregar servicio
          </Button>
        </div>
      </div>
    )
  }

  if (loading) return <Loading />
  return (
    <div className={styles.container}>
      {renderHeader()}
      <Table columns={header} dataSource={items} />
    </div>
  )
}

export default Services
