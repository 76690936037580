import styled from 'styled-components'
import { motion } from 'framer-motion'

const variants = {
  visible: {
    opacity: 1,
    transition: { duration: 0.2 },
  },
  hidden: {
    opacity: 0,
    transition: { duration: 0.2 },
  },
}

export const BoxStyled = styled(motion.div).attrs(() => ({
  initial: 'hidden',
  variants,
}))`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: 10%;
`
