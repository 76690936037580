import * as React from 'react'
import styles from './styles.module.css'
import numeral from 'numeral'

type MeetingTypes = {
  key: number
  services: {
    serviceName: string
    price: number
  }
  client: string
  totalAmount: string
  date: Date
}

export const header = [
  {
    title: 'Servicios',
    dataIndex: 'services',
    key: 'services',
    render: (text: string, record: MeetingTypes) => {
      const style =
        record.key % 2
          ? { background: '#fff', color: '#221F1F' }
          : { background: 'rgba(245, 213, 242, 0.4)', color: '#221F1F' }
      return {
        props: {
          style,
        },
        children: (
          <div className={styles.listSelection}>
            {record.services.map(service => {
              return <div>{service.serviceName}</div>
            })}
          </div>
        ),
      }
    },
  },
  {
    title: 'Cliente',
    dataIndex: 'client',
    key: 'client',
    render: (text: string, record: MeetingTypes) => {
      const style =
        record.key % 2
          ? { background: '#fff', color: '#221F1F' }
          : { background: 'rgba(245, 213, 242, 0.4)', color: '#221F1F' }
      return {
        props: {
          style,
        },
        children: <div style={{ fontSize: 15 }}>{text}</div>,
      }
    },
  },
  {
    title: 'Fecha agendada',
    dataIndex: 'date',
    key: 'date',
    render: (text: string, record: MeetingTypes) => {
      const style =
        record.key % 2
          ? { background: '#fff', color: '#221F1F' }
          : { background: 'rgba(245, 213, 242, 0.4)', color: '#221F1F' }
      return {
        props: {
          style,
        },
        children: <div style={{ fontSize: 15 }}>{text}</div>,
      }
    },
  },
  {
    title: 'Monto total',
    dataIndex: 'totalAmount',
    key: 'totalAmount',
    render: (text: string, record: MeetingTypes) => {
      const style =
        record.key % 2
          ? { background: '#fff', color: '#221F1F' }
          : { background: 'rgba(245, 213, 242, 0.4)', color: '#221F1F' }
      return {
        props: {
          style,
        },
        children: (
          <div style={{ fontSize: 15 }}>{numeral(text).format('$ 0,0')}</div>
        ),
      }
    },
  },
]
