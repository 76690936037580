import slugify from '@src/helpers/slugify'

type ProfessionalTypes = {
  key: number
  professionalId: string
  name: string
  rut: string
  email: string
  phone: string
  rating?: number
}

export default (text: string, items: ProfessionalTypes[]) => {
  const toFind = slugify(text)
  const newItems = items.filter(
    (item: any) =>
      slugify(item.name).includes(toFind) ||
      slugify(item.rut).includes(toFind) ||
      slugify(item.email).includes(toFind) ||
      slugify(item.phone).includes(toFind),
  )

  if (!newItems) return []
  return newItems
}
