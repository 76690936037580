import React, { useState, useEffect } from 'react'
import styles from './styles.module.css'
import { Table, Button, Input } from 'antd'
import { SearchOutlined, PlusCircleOutlined } from '@ant-design/icons'
import Loading from '@src/components/loading'
import { useQuery } from '@apollo/client'
import searchFilter from './searchFilter'
import { navigate } from 'gatsby'
import { header } from './data'
import { isEmpty } from 'ramda'
import { buttonStyle, customInput } from './customStyles'
import { ALL_PROFESSIONALS } from '@src/helpers/queries/professionals.gql'
import TitleHeader from '@src/components/titleHeader'

type ProfessionalTypes = {
  key: number
  professionalId: string
  name: string
  rut: string
  email: string
  phone: string
  rating: number
}

interface ProfessionalData {
  id: string
  rut: string
  phone: string
  name: string
  email: string
  rating: number
}

interface ProfessionalInventoryData {
  allProfessionals: ProfessionalData[]
}

const Professionals: React.FC = () => {
  const [filter, setFilter] = useState<string>(null)
  const [items, setItems] = useState<ProfessionalTypes[]>([])
  const [professionals, setProfessionals] = useState<ProfessionalTypes[]>([])
  const { loading, error, data } = useQuery<
    ProfessionalInventoryData,
    ProfessionalData
  >(ALL_PROFESSIONALS, {
    notifyOnNetworkStatusChange: true,
  })

  const goCreate = () => {
    navigate('/professionals/create')
  }

  useEffect(() => {
    const addProfessionals = () => {
      if (data && data.allProfessionals) {
        const parseData = data.allProfessionals.map(
          (professional, idx: number) => {
            return {
              key: idx,
              professionalId: professional.id,
              name: professional.name,
              rut: professional.rut,
              email: professional.email,
              phone: professional.phone,
              rating: professional.rating,
            }
          },
        )
        setProfessionals(parseData)
      }
    }

    addProfessionals()
  }, [data])

  useEffect(() => {
    const setTableItems = () => {
      if (!filter) setItems(professionals)
      else {
        const newItems = searchFilter(filter, professionals)
        setItems(newItems)
      }
    }

    setTableItems()
  }, [filter, professionals])

  const onTextChange = (text: { target: { value: string } }) => {
    if (isEmpty(text.target.value)) setFilter(null)
    else setFilter(text.target.value)
  }

  const renderHeader = () => {
    return (
      <div className={styles.header}>
        <div className={styles.logoContent}>
          <TitleHeader label="profesionales" />
        </div>
        <div className={styles.inputs}>
          <Input
            style={{ ...customInput }}
            placeholder="Buscar"
            prefix={<SearchOutlined />}
            onChange={onTextChange}
          />
          <Button
            onClick={goCreate}
            style={{ ...buttonStyle }}
            type="primary"
            shape="round"
            icon={<PlusCircleOutlined />}>
            Crear Profesional
          </Button>
        </div>
      </div>
    )
  }

  if (loading) return <Loading />
  return (
    <div className={styles.container}>
      {renderHeader()}
      <Table columns={header} dataSource={items} />
    </div>
  )
}

export default Professionals
