import React from 'react'
import styles from './styles.module.css'
import { FileExcelOutlined } from '@ant-design/icons'
import { buttonStyle, datePickerField } from './customStyles'
import downloadFile from '@src/helpers/download'
import { Button, DatePicker } from 'antd'
import rutParser from '@src/helpers/rutParser'
import numeral from 'numeral'
import dayjs from 'dayjs'
import XLSX from 'xlsx'

type DatesSelectedTypes = {
  dateFrom?: Date
  dateTo?: Date
}

type ItemsTypes = {
  id: string
  key: number
  name: string
  rut: string
  payment: number
}

type DateFilterProps = {
  items: ItemsTypes[]
  onDateFilterChange: Function
  datesSelected: DatesSelectedTypes
}

const DateFilter: React.FC<DateFilterProps> = ({
  items,
  onDateFilterChange,
  datesSelected,
}) => {
  const onCreateExcel = () => {
    const { dateFrom, dateTo } = datesSelected
    const subject = `${
      dateFrom ? dayjs(dateFrom).format('MMMM D, YYYY h:mm A') : 'sin fecha'
    } - ${
      dateTo
        ? dayjs(dateTo).format('MMMM D, YYYY h:mm A')
        : dayjs(new Date()).format('MMMM D, YYYY h:mm A')
    }`
    const wb = XLSX.utils.book_new()
    wb.Props = {
      Title: 'Pagos a professionales',
      Subject: subject,
      Author: 'The Cat Club Admin-App',
      CreatedDate: new Date(),
    }
    wb.SheetNames.push('Pagos-Profesionales')
    const header = ['Nombre del profesional', 'RUT', `Monto a pagar ${subject}`]
    const ws_data = []
    ws_data.push([...header])

    for (const item of items) {
      ws_data.push([
        item.name,
        rutParser(item.rut),
        numeral(item.payment).format('$0,0'),
      ])
    }

    const ws = XLSX.utils.aoa_to_sheet(ws_data)
    wb.Sheets['Pagos-Profesionales'] = ws
    var wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'binary' })
    downloadFile(wbout, 'Pagos-profesionales.xlsx', null)
  }

  const renderExcelButton = () => (
    <Button
      onClick={onCreateExcel}
      style={{ ...buttonStyle }}
      type="primary"
      shape="round"
      icon={<FileExcelOutlined size={20} />}>
      Descargar Excel
    </Button>
  )

  const onDateFromChange = (value: any) => onDateFilterChange(value, 'dateFrom')

  const onDateToChange = (value: any) => onDateFilterChange(value, 'dateTo')

  const renderDateFrom = () => (
    <div className={styles.calendar}>
      <DatePicker style={{ ...datePickerField }} onChange={onDateFromChange} />
    </div>
  )

  const renderDateTo = () => (
    <div className={styles.calendar}>
      <DatePicker style={{ ...datePickerField }} onChange={onDateToChange} />
    </div>
  )

  return (
    <div className={styles.container}>
      <div className={styles.description}>Selecciona un rango de fechas</div>
      <div className={styles.content}>
        <div className={styles.section}>
          <div className={styles.label}>Fecha inicio</div>
          {renderDateFrom()}
        </div>
        <div className={styles.centerSection}>
          <div className={styles.label}>Fecha término</div>
          {renderDateTo()}
        </div>
        <div className={styles.lastSection}>{renderExcelButton()}</div>
      </div>
    </div>
  )
}

export default DateFilter
