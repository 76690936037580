import styled from 'styled-components'
import { motion } from 'framer-motion'

const variants = {
  visible: {
    opacity: 1,
    transition: { duration: 0.4 },
    zIndex: 100,
    height: 'auto',
  },
  hidden: {
    opacity: 0,
    transition: { duration: 0.6 },
    zIndex: -100,
    height: 0,
  },
}

export const BoxStyled = styled(motion.div).attrs(() => ({
  initial: 'hidden',
  variants,
}))`
  height: 255px !important;
  width: 300px;
  background-color: #fff;
  box-shadow: rgba(0, 0, 0, 0.12) 0px 4px 10px;
  position: relative;
  top: 55px;
  left: -110px;
  border-width: 2px;
  border-radius: 15px;
  padding: 20px;
  border-color: #000;
`
