import React, { useState, useEffect } from 'react'
import styles from './styles.module.css'
import { Table, Button, Input } from 'antd'
import { SearchOutlined, PlusCircleOutlined } from '@ant-design/icons'
import TitleHeader from '@src/components/titleHeader'
import Loading from '@src/components/loading'
import { useQuery } from '@apollo/client'
import searchFilter from './searchFilter'
import ServicesLogo from './logo'
import { navigate } from 'gatsby'
import { header } from './data'
import { isEmpty } from 'ramda'
import { buttonStyle, customInput } from './customStyles'
import { USERS } from '@src/helpers/queries/users.gql'
import numeral from 'numeral'

interface Auth0Types {
  name: string
  email: string
}

interface UsersTypes {
  id: string
  auth0Data: Auth0Types
  rut: string
  phone: string
}

interface UsersInventory {
  allUsers: UsersTypes[]
}

type LocalUsersTypes = {
  id: string
  key: number
  name: string
  rut: string
  email: string
  phone: string
}

const Services: React.FC = () => {
  const [filter, setFilter] = useState<string>(null)
  const [items, setItems] = useState<LocalUsersTypes[]>([])
  const [users, setUsers] = useState<LocalUsersTypes[]>([])
  const { loading, error, data } = useQuery<UsersInventory>(USERS, {
    notifyOnNetworkStatusChange: true,
  })

  useEffect(() => {
    const addServices = () => {
      if (data && data.allUsers) {
        const parseData = data.allUsers.map((user, idx: number) => {
          return {
            key: idx,
            id: user.id,
            name: user.auth0Data.name,
            email: user.auth0Data.email,
            rut: user.rut,
            phone: user.phone,
          }
        })
        setUsers(parseData)
      }
    }

    addServices()
  }, [data])

  useEffect(() => {
    const setTableItems = () => {
      if (!filter) setItems(users)
      else {
        const newItems = searchFilter(filter, users)
        setItems(newItems)
      }
    }

    setTableItems()
  }, [filter, users])

  const onTextChange = (text: { target: { value: string } }) => {
    if (isEmpty(text.target.value)) setFilter(null)
    else setFilter(text.target.value)
  }

  const renderHeader = () => {
    return (
      <div className={styles.header}>
        <div className={styles.logoContent}>
          <TitleHeader label="usuarios" />
        </div>
        <div className={styles.inputs}>
          <Input
            style={{ ...customInput }}
            placeholder="Buscar"
            prefix={<SearchOutlined />}
            onChange={onTextChange}
          />
        </div>
      </div>
    )
  }

  if (loading) return <Loading />
  return (
    <div className={styles.container}>
      {renderHeader()}
      <Table columns={header} dataSource={items} />
    </div>
  )
}

export default Services
