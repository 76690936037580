type ProfessionalTypes = {
  id: string
  name: string
  email: string
  rut: string
  title: string
  phone: string
  socialNetworks: {
    facebook: string
    instagram: string
    twitter: string
  }
  backgroundImage: {
    name: string
    uid: string
    url: string
    status: string
  }[]
  picture: {
    name: string
    uid: string
    url: string
    status: string
  }[]
  bankData: {
    bank: string
    accountType: string
    accountNumber: string
  }
  portfolio: {
    name: string
    uid: string
    url: string
    status: string
  }[]
  specialtyNames: string[]
  services: string[]
  specialties: string[]
  servicesData: {
    id: string
    title: string
    specialtyName: string
    details: string
    duration: number
    price: number
  }[]
  rating?: number
}

export const professionalInformation = (data: ProfessionalTypes) => {
  return {
    name: data.name,
    rut: data.rut,
    title: data.title,
    email: data.email,
    phone: data.phone,
    socialNetworks: { ...data.socialNetworks },
    backgroundImage: data.backgroundImage ? [...data.backgroundImage] : [],
    picture: data.picture ? [...data.picture] : [],
    bankData: { ...data.bankData },
    portfolio: [...data.portfolio],
    specialties: [...data.specialties],
    services: [...data.services],
  }
}

export const professionalServices = (data: ProfessionalTypes) => {
  const availableServices = [...data.servicesData]

  return availableServices.map(service => {
    return {
      details: service.details,
      specialtyName: service.specialtyName,
      id: service.id,
      title: service.title,
      duration: service.duration,
      price: service.price,
    }
  })
}
