export const buttonStyle = {
  borderRadius: 30,
  color: '#fff',
  background: '#CE2EBC',
  height: 50,
  width: 330,
}

export const deleteButtonStyle = {
  borderRadius: 30,
  color: '#fff',
  background: '#ff4d4f',
  height: 50,
  width: 330,
  marginLeft: 5,
}

export const categoryButton = {
  borderRadius: 30,
  color: 'rgba(128, 98, 125, 0.5)',
  borderColor: 'rgba(128, 98, 125, 0.5)',
  backgroundColor: '#fff',
  height: 50,
  width: 240,
  marginLeft: '2%',
}
