import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'

type FacebookType = {
  className: object
}

const Facebook: React.FC<FacebookType> = ({ className }) => {
  const data = useStaticQuery(graphql`
    query {
      facebook: file(relativePath: { eq: "facebook-2.png" }) {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <Img className={className} fluid={data.facebook.childImageSharp.fluid} />
  )
}

export default Facebook
