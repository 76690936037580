import React from 'react'
import styles from './styles.module.css'
import { MdStar, MdStarBorder, MdStarHalf } from 'react-icons/md'

type CalificationProps = {
  value: number
}

const Calification: React.FC<CalificationProps> = ({ value }) => {
  const isSelected = (index: number) => index <= value

  const isHalf = (index: number) => index - 0.5 <= value && index > value

  const renderStar = (index: number) => {
    const Component = isSelected(index)
      ? MdStar
      : isHalf(index)
      ? MdStarHalf
      : MdStarBorder
    return <Component key={index} size={20} color="#E2D50C" />
  }

  return (
    <div className={styles.container}>
      {[1, 2, 3, 4, 5].map((number: number) => renderStar(number))}
    </div>
  )
}

export default Calification
