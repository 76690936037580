export const categoryButton = {
  borderRadius: 30,
  color: 'rgba(128, 98, 125, 0.5)',
  borderColor: 'rgba(128, 98, 125, 0.5)',
  backgroundColor: '#fff',
  height: 50,
  width: 240,
  marginLeft: '2%',
}

export const createButton = {
  borderRadius: 30,
  color: '#fff',
  height: 30,
  width: '100%',
}
