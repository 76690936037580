import styled from 'styled-components'
import { motion } from 'framer-motion'

const variants = {
  visible: {
    opacity: 1,
    height: 'auto',
    transition: { duration: 0.2 },
  },
  hidden: {
    opacity: 0,
    height: 0,
    transition: { duration: 0.2 },
  },
}

export const BoxStyled = styled(motion.div).attrs(() => ({
  initial: 'hidden',
  variants,
}))`
  padding: 20px 0;
  margin-top: 10px;
  border: 1px solid rgba(128, 98, 125, 0.5);
  border-radius: 30px;
`
