import slugify from '@src/helpers/slugify'

type ServicesTypes = {
  id: string
  specialtyId: string
  title: string
  price: number
  duration: number
  details: string
  specialtyName: string
}

export default (text: string, items: ServicesTypes[]) => {
  const toFind = slugify(text)
  const newItems = items.filter(
    item =>
      slugify(item.specialtyName).includes(toFind) ||
      slugify(item.title).includes(toFind) ||
      slugify(item.details).includes(toFind) ||
      slugify(item.duration && item.duration.toString()).includes(toFind) ||
      slugify(item.price && item.price.toString()).includes(toFind),
  )

  if (!newItems) return []
  return newItems
}
