import React, { useState, useEffect } from 'react'
import styles from './styles.module.css'
import { Table } from 'antd'
import Loading from '@src/components/loading'
import { PROFESSIONAL_MEETINGS_WITH_TIME } from '@src/helpers/queries/meetings.gql'
import { MeetingsData } from './types'
import { useQuery } from '@apollo/client'
import DetailsLogo from './logo'
import { header } from './data'
import { isEmpty } from 'ramda'
import dayjs from 'dayjs'
import TitleHeader from '@src/components/titleHeader'

type MeetingsTypes = {
  key: string
  id: string
  serviceName: string
  client: string
  price: number
  date: Date
}

type ServiceDetailsProps = {
  professionalId: string
  dateFrom: string
  dateTo: string
}

const ServiceDetails: React.FC<ServiceDetailsProps> = ({
  professionalId,
  dateFrom,
  dateTo,
}) => {
  const [items, setItems] = useState<MeetingsTypes[]>([])
  const { loading, error, data } = useQuery<MeetingsData>(
    PROFESSIONAL_MEETINGS_WITH_TIME,
    {
      variables: {
        professionalId,
        dateFrom: new Date(dateFrom),
        dateTo: new Date(dateTo),
      },
      skip: !professionalId,
    },
  )

  useEffect(() => {
    if (data && data.allProfessionalMeetings) {
      let idx = 1
      const newParsed = []
      for (const meeting of data.allProfessionalMeetings)
        for (const service of meeting.serviceDetails) {
          idx++
          newParsed.push({
            key: idx,
            id: meeting.id,
            serviceName: service.serviceName,
            client: meeting?.user?.auth0Data?.name || '',
            price: service.price,
            date: dayjs(meeting.metadata?.date).format('DD MMMM YYYY'),
          })
        }

      setItems([...newParsed])
    }
  }, [data])

  const renderHeader = () => {
    return (
      <div className={styles.header}>
        <TitleHeader label="detalles" />
      </div>
    )
  }

  if (error) return <span>Error...</span>
  if (loading) return <Loading />
  return (
    <div className={styles.container}>
      {renderHeader()}
      <Table columns={header} dataSource={items} />
    </div>
  )
}

export default ServiceDetails
