import React, { useState } from 'react'
import styles from './styles.module.css'
import { Input, Upload, message, Select } from 'antd'
import { personalData, socialNetworks } from './form'
import { AiOutlineCamera } from 'react-icons/ai'
import { DownloadOutlined } from '@ant-design/icons'
import { banks, accounts } from './banksData'
import { socialInput } from './customStyles'
import Portfolio from './portfolio'
import HeaderLogo from './logo'
import { isEmpty } from 'ramda'

const { Option } = Select

type FormTypes = {
  name: string
  rut: string
  title: string
  email: string
  phone: string
  bankData?: {
    bank: string
    accountType: string
    accountNumber: string
  }
  socialNetworks?: {
    facebook: string
    twitter: string
    instagram: string
  }
  portfolio?: {
    name: string
    uid: string
    url: string
    status: string
  }[]
  services: string[]
  specialties: string[]
  backgroundImage: {
    name: string
    uid: string
    url: string
    status: string
  }[]
  picture: {
    name: string
    uid: string
    url: string
    status: string
  }[]
}

type UploadTypes = {
  action: string
  data: object
  file: File
  filename: string
  headers: object
  onError: Function
  onProgress: Function
  onSuccess: Function
  withCredentials: boolean
}

type DeleteFileTypes = {
  name: string
  status?: string
  uid: string
  url?: string
}

type PersonalDataTypes = {
  form: FormTypes
  onFormChange: Function
  handleUpload: Function
  handleDelete: Function
}

const PersonalData: React.FC<PersonalDataTypes> = ({
  form,
  onFormChange,
  handleUpload,
  handleDelete,
}) => {
  const onDelete = async (params: DeleteFileTypes, type: string) => {
    const deletion = await handleDelete({ ...params })
    if (deletion) {
      onFormChange(null, type, [])
      message.success('Imagen eliminada')
    } else message.error(`Ocurrió un error al eliminar la imagen`)
  }

  const uploadFile = async (params: UploadTypes, type: string) => {
    if (!isEmpty(form[type])) return
    if (!form.email || isEmpty(form.email)) {
      message.error(
        'Para subir imagenes, debes agregar el email del profesional',
      )
      return
    }

    const upload = await handleUpload({ ...params, userEmail: form.email })
    if (upload) {
      onFormChange(null, type, [upload])
      message.success('Imagen del profesional subida con exito')
    } else message.error(`Ocurrió un error al subir la imagen`)
  }

  const setBank = (value: string) => onFormChange('bankData', 'bank', value)

  const setAccountType = (value: string) =>
    onFormChange('bankData', 'accountType', value)

  const draggerProps = {
    onPreview: () => null,
    multiple: false,
    showUploadList: {
      showDownloadIcon: true,
      downloadIcon: <DownloadOutlined />,
      showRemoveIcon: true,
    },
  }

  const localChange = (text: any) => {
    if (!text.target) return
    const { name, value } = text.target
    const social = ['facebook', 'twitter', 'instagram']
    if (social.includes(name)) onFormChange('socialNetworks', name, value)
    else if (name === 'accountNumber') onFormChange('bankData', name, value)
    else onFormChange(null, name, value)
  }

  const renderPortfolio = () => {
    return (
      <div className={styles.portfolio}>
        <div className={styles.section}>Portafolio</div>
        <Portfolio
          userEmail={form.email}
          fileList={form.portfolio}
          handleUpload={handleUpload}
          handleDelete={handleDelete}
          onFormChange={onFormChange}
        />
      </div>
    )
  }

  const renderSocialNetworks = () => {
    return (
      <div className={styles.socialNetworks}>
        <div className={styles.section}>Redes sociales</div>
        <div className={styles.socialContent}>
          {socialNetworks.map((network, idx) => {
            const Icon = network.icon
            return (
              <Input
                key={idx.toString()}
                name={network.name}
                style={{ ...socialInput }}
                prefix={<Icon className={styles.icons} />}
                value={form.socialNetworks && form.socialNetworks[network.name]}
                placeholder={network.placeholder}
                onChange={localChange}
              />
            )
          })}
        </div>
      </div>
    )
  }

  const renderBankSection = () => {
    return (
      <div className={styles.banks}>
        <div className={styles.section}>Datos bancarios</div>
        <div className={styles.content}>
          <div className={styles.field}>
            <div className={styles.label}>Banco</div>
            <Select
              defaultValue={form.bankData && form.bankData.bank}
              onChange={setBank}
              style={{ width: '100%' }}>
              {banks.map((bank, idx) => (
                <Option key={idx.toString()} value={bank.value}>
                  {bank.label}
                </Option>
              ))}
            </Select>
          </div>
          <div className={styles.field}>
            <div className={styles.label}>Tipo de cuenta</div>
            <Select
              defaultValue={form.bankData && form.bankData.accountType}
              onChange={setAccountType}
              style={{ width: '100%' }}>
              {accounts.map((account, idx) => (
                <Option key={idx.toString()} value={account.value}>
                  {account.label}
                </Option>
              ))}
            </Select>
          </div>
          <div className={styles.field}>
            <div className={styles.label}>Número de cuenta</div>
            <Input
              name="accountNumber"
              type="bankData"
              style={{ borderRadius: 30, height: 50 }}
              value={form.bankData.accountNumber}
              placeholder="000-00000-000"
              onChange={localChange}
            />
          </div>
        </div>
      </div>
    )
  }

  const renderUploadBackgroundImage = () => {
    return (
      <Upload
        {...draggerProps}
        fileList={form.backgroundImage}
        onRemove={params => onDelete(params, 'backgroundImage')}
        customRequest={params => uploadFile(params, 'backgroundImage')}>
        <div className={styles.emptyLabel} />
        <div className={styles.uploadPicture}>
          <AiOutlineCamera size={25} color="#aeaeae80" />
          <div className={styles.placeholder}>Agregar imagen de fondo</div>
        </div>
      </Upload>
    )
  }

  const renderUploadProfileImage = () => {
    return (
      <Upload
        {...draggerProps}
        fileList={form.picture}
        onRemove={params => onDelete(params, 'picture')}
        customRequest={params => uploadFile(params, 'picture')}>
        <div className={styles.emptyLabel} />
        <div className={styles.uploadPicture}>
          <AiOutlineCamera size={25} color="#aeaeae80" />
          <div className={styles.placeholder}>Agregar imagen de perfil</div>
        </div>
      </Upload>
    )
  }

  const renderPersonalDataForm = () => {
    return personalData.map((field, idx) => {
      return (
        <div className={styles.field} key={idx.toString()}>
          <div className={styles.label}>{field.label}</div>
          <Input
            key={idx.toString()}
            name={field.name}
            value={form[field.name]}
            placeholder={field.placeholder}
            style={{ borderRadius: 30, height: 50 }}
            onChange={localChange}
          />
        </div>
      )
    })
  }

  const renderHeader = () => (
    <div className={styles.header}>
      <div className={styles.subTitle}>Datos personales</div>
    </div>
  )

  return (
    <div className={styles.container}>
      {renderHeader()}
      <div className={styles.personalData}>
        {renderPersonalDataForm()}
        {renderUploadProfileImage()}
        {renderUploadBackgroundImage()}
      </div>
      {renderBankSection()}
      {renderSocialNetworks()}
      {renderPortfolio()}
    </div>
  )
}

export default PersonalData
