import slugify from '@src/helpers/slugify'

type LocalProfessionalsTypes = {
  id: string
  key: number
  name: string
  rut: string
  payment: number
  dateFrom: string
  dateTo: string
}

export default (text: string, items: LocalProfessionalsTypes[]) => {
  const toFind = slugify(text)
  const newItems = items.filter(
    item =>
      slugify(item.name).includes(toFind) || slugify(item.rut).includes(toFind),
  )

  if (!newItems) return []
  return newItems
}
