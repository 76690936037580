import React, { useState, useEffect } from 'react'
import styles from './styles.module.css'
import { PROFESSIONAL_WITH_PAYMENTS } from '@src/helpers/queries/professionals.gql'
import { INTERVAL_PAYMENT } from '@src/helpers/queries/meetings.gql'
import {
  ProfessionalData,
  ProfessionalTypes,
  PaymentDetail,
  PaymentDetailData,
} from './types'
import { banks, accountTypes } from '@src/helpers/banks'
import TitleHeader from '@src/components/titleHeader'
import ServiceDetails from './service-details'
import BackArrow from '@src/components/back-arrow'
import Loading from '@src/components/loading'
import { useQuery } from '@apollo/client'
import parser from './parser'
import numeral from 'numeral'
import { isEmpty } from 'ramda'
import dayjs from 'dayjs'

import 'moment/locale/es'

dayjs.locale('es')

type ProfessionalPaymentsProps = {
  professionalId: string
  dateFrom: string
  dateTo: string
}

const ProfessionalPayments: React.FC<ProfessionalPaymentsProps> = ({
  professionalId,
  dateFrom,
  dateTo,
}) => {
  const [professional, setProfessional] = useState<ProfessionalTypes>({})
  const [paymentDetail, setPaymentDetail] = useState<PaymentDetail>({})
  const {
    loading: pDetailLoading,
    error: pDetailError,
    data: pDetailData,
  } = useQuery<PaymentDetailData>(INTERVAL_PAYMENT, {
    variables: {
      professionalId,
      dateFrom: new Date(dateFrom),
      dateTo: new Date(dateTo),
    },
    skip: !professionalId || !dateFrom || !dateTo,
  })
  const { loading, error, data } = useQuery<ProfessionalData>(
    PROFESSIONAL_WITH_PAYMENTS,
    {
      variables: { id: professionalId },
      skip: !professionalId,
    },
  )

  useEffect(() => {
    if (pDetailData && pDetailData.intervalPayment)
      setPaymentDetail({
        ...pDetailData.intervalPayment,
      })

  }, [pDetailData])

  useEffect(() => {
    if (data && data.Professional) {
      const parsedData = parser(data.Professional)
      setProfessional({ ...parsedData })
    }
  }, [data])

  const renderRightItem = (label: string, value: string) => (
    <div className={styles.rightItem}>
      <div className={styles.label}>{label}</div>
      <div className={styles.rightValue}>{value}</div>
    </div>
  )

  const renderLeftItem = (label: string, value: string) => (
    <div className={styles.leftItem}>
      <div className={styles.label}>{label}</div>
      <div className={styles.value}>{value}</div>
    </div>
  )

  const renderRightPanel = () => {
    const {
      totalSaleValue,
      clearanceValue,
      iva,
      total,
      professionalPayment,
    } = paymentDetail
    return (
      <div className={styles.rightPanel}>
        <div className={styles.rightHeader}>
          <div>{dayjs().format('DD MMMM YYYY')}</div>
          <div>
            <b>N° Boleta</b> #{'40592'}
          </div>
        </div>
        {renderRightItem(
          'Valor venta (cobrado a cliente)',
          numeral(totalSaleValue).format('$0,0'),
        )}
        {renderRightItem(
          'Valor despacho',
          `- ${numeral(clearanceValue).format('$0,0')}`,
        )}
        {renderRightItem('Valor IVA', `- ${numeral(iva).format('$0,0')}`)}
        {/* {renderRightItem(
          'Transbank (1.5%)',
          `- ${numeral(transbank).format('$0,0')}`,
        )} */}
        <div className={styles.divider} />
        {renderRightItem('TOTAL', numeral(total).format('$0,0'))}
        <div className={styles.divider} />
        {renderRightItem(
          'Profesional (70%) + Despachos',
          numeral(professionalPayment).format('$0,0'),
        )}
        <div className={styles.divider} />
        <div className={styles.rightHeader}>
          <div>
            <b>Monto a pagar</b>
          </div>
          <div className={styles.totalPrice}>
            <b>{numeral(professionalPayment).format('$0,0')}</b>
          </div>
        </div>
      </div>
    )
  }

  const renderLeftPanel = () => {
    const { name, rut, bankData, phone, email, picture } = professional
    return (
      <div className={styles.leftPanel}>
        <div className={styles.professional}>
          <img
            className={styles.image}
            src={
              picture ||
              'https://xavierferras.com/wp-content/uploads/2019/02/266-Persona.jpg'
            }
            alt={Math.random().toString()}
          />

          <div className={styles.professionalName}>{name}</div>
        </div>
        {renderLeftItem(
          'Periodo trabajando',
          `${dayjs(dateFrom).format('DD MMMM YYYY')}  al  ${dayjs(
            dateTo,
          ).format('DD MMMM YYYY')}`,
        )}
        <div className={styles.divider} />
        {renderLeftItem('Nombre', name)}
        {renderLeftItem('RUT', rut)}
        {renderLeftItem('Banco', banks[bankData.bank])}
        {renderLeftItem('Tipo de cuenta', accountTypes[bankData.accountType])}
        {renderLeftItem('N° de cuenta', bankData.accountNumber)}
        <div className={styles.divider} />
        {renderLeftItem('Teléfono', phone)}
        {renderLeftItem('Email', email)}
      </div>
    )
  }

  if (error || pDetailError) return <span>Error</span>
  if (
    loading ||
    pDetailLoading ||
    isEmpty(professional) ||
    isEmpty(paymentDetail)
  )
    return <Loading />
  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <BackArrow path="/payments" />
        <TitleHeader label="pagos" />
      </div>
      <div className={styles.content}>
        {renderLeftPanel()}
        {renderRightPanel()}
      </div>
      <ServiceDetails
        professionalId={professional.id}
        dateFrom={dateFrom}
        dateTo={dateTo}
      />
    </div>
  )
}

export default ProfessionalPayments
