import React from 'react'
import styles from './styles.module.css'

type TitleHeaderProps = {
  label: string
}

const TitleHeader: React.FC<TitleHeaderProps> = ({ label }) => {
  return <div className={styles.container}>{label || 'sin titulo'}</div>
}

export default TitleHeader
