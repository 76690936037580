import rutParser from '@src/helpers/rutParser'
import numeral from 'numeral'
import * as React from 'react'

import styles from './styles.module.css'

type UsersTypes = {
  id: string
  key: number
  name: string
  rut: string
  email: string
  phone: string
}

export const header = [
  {
    title: 'Nombre',
    dataIndex: 'name',
    key: 'name',
    render: (text: string, record: UsersTypes) => {
      const style =
        record.key % 2
          ? { background: '#fff', color: '#221F1F' }
          : { background: 'rgba(245, 213, 242, 0.4)', color: '#221F1F' }
      return {
        props: {
          style,
        },
        children: <div style={{ fontSize: 15 }}>{text}</div>,
      }
    },
  },
  {
    title: 'RUT',
    dataIndex: 'rut',
    key: 'rut',
    render: (text: string, record: UsersTypes) => {
      const style =
        record.key % 2
          ? { background: '#fff', color: '#221F1F' }
          : { background: 'rgba(245, 213, 242, 0.4)', color: '#221F1F' }
      return {
        props: {
          style,
        },
        children: <div style={{ fontSize: 15 }}>{rutParser(text)}</div>,
      }
    },
  },
  {
    title: 'Email',
    dataIndex: 'email',
    key: 'email',
    render: (text: string, record: UsersTypes) => {
      const style =
        record.key % 2
          ? { background: '#fff', color: '#221F1F' }
          : { background: 'rgba(245, 213, 242, 0.4)', color: '#221F1F' }
      return {
        props: {
          style,
        },
        children: <div style={{ fontSize: 15 }}>{text}</div>,
      }
    },
  },
  {
    title: 'Teléfono',
    dataIndex: 'phone',
    key: 'phone',
    render: (text: string, record: UsersTypes) => {
      const style =
        record.key % 2
          ? { background: '#fff', color: '#221F1F' }
          : { background: 'rgba(245, 213, 242, 0.4)', color: '#221F1F' }
      return {
        props: {
          style,
        },
        children: <div style={{ fontSize: 15 }}>{text}</div>,
      }
    },
  },
]
