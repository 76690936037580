import React, { useState, useEffect } from 'react'
import styles from './styles.module.css'
import { Input, message, Button, Select } from 'antd'
import fields from './fields'
import { UPDATE_SERVICE } from '@src/helpers/mutations/services.gql'
import { useMutation, useQuery } from '@apollo/client'
import { buttonStyle, categoryButton } from './customStyles'
import TitleHeader from '@src/components/titleHeader'
import { UpdateServiceTypes } from './mutationTypes'
import Loading from '@src/components/loading'
import BackArrow from '@src/components/back-arrow'
import { ServiceQuery } from './queryTypes'
import SubCategory from '../create/sub-category'
import { navigate } from 'gatsby'
import { isEmpty } from 'ramda'
import { SERVICE } from '@src/helpers/queries/services.gql'
import { ALL_SPECIALTIES } from '@src/helpers/queries/specialties.gql'
import { ALL_SUBCATEGORIES } from '@src/helpers/queries/subCategories.gql'
import Remove from './remove'

const { TextArea } = Input
const { Option } = Select

type OptionTypes = {
  label: string
  value: string
}

type FormTypes = {
  title: string
  specialtyId: string
  price: string
  duration: string
  details: string
  subCategoryId: string
}

type UpdateServiceProps = {
  serviceId: string
}

interface allSubCategories {
  id: string
  name: string
  specialtyId: string
}

interface SubCategoriesIntentory {
  allSubCategories: allSubCategories[]
}

interface AllSpecialties {
  id: string
  name: string
  description: string
}

interface SpecialtiesIntentory {
  allSpecialties: AllSpecialties[]
}

const Update: React.FC<UpdateServiceProps> = ({ serviceId }) => {
  const [form, setForm] = useState<FormTypes>({})
  const [updateLoading, setUpdateLoading] = useState<boolean>(false)
  const [updateService] = useMutation<UpdateServiceTypes>(UPDATE_SERVICE)
  const [specialties, setSpecialties] = useState<OptionTypes[]>([])
  const [subCategories, setSubCategories] = useState<object>({})
  const {
    loading: serviceLoading,
    error: serviceError,
    data: serviceData,
  } = useQuery<ServiceQuery>(SERVICE, {
    variables: { id: serviceId },
    skip: !serviceId,
  })

  const {
    loading: specialtyLoading,
    error: specialtyError,
    data: specialtyData,
  } = useQuery<SpecialtiesIntentory>(ALL_SPECIALTIES)

  const {
    loading: subLoading,
    error: subError,
    data: subCategoriesData,
    refetch,
  } = useQuery<SubCategoriesIntentory>(ALL_SUBCATEGORIES)

  useEffect(() => {
    if (subCategoriesData && subCategoriesData.allSubCategories) {
      const newData = {}
      for (const sub of subCategoriesData.allSubCategories) {
        if (newData[sub.specialtyId]) {
          newData[sub.specialtyId].push({
            value: sub.id,
            label: sub.name,
          })
        } else {
          newData[sub.specialtyId] = [
            {
              value: sub.id,
              label: sub.name,
            },
          ]
        }
      }
      setSubCategories(newData)
    }
  }, [subCategoriesData])

  useEffect(() => {
    if (serviceData && serviceData.Service) {
      const copy = { ...serviceData.Service }
      delete copy.id
      delete copy.specialtyName
      setForm({
        ...copy,
        price: copy.price.toString(),
        duration: copy.duration.toString(),
      })
    }
  }, [serviceData])

  useEffect(() => {
    if (specialtyData && specialtyData.allSpecialties) {
      const newData = specialtyData.allSpecialties.map(specialty => {
        return {
          value: specialty.id,
          label: specialty.name,
        }
      })
      setSpecialties([...newData])
    }
  }, [specialtyData])

  const onUpdateService = async () => {
    setUpdateLoading(true)
    try {
      await updateService({
        variables: {
          id: serviceId,
          input: {
            ...form,
            price: parseInt(form.price),
            duration: parseInt(form.duration),
          },
        },
      })
      message.success('Servicio actualizado con exito')
      navigate('/services')
    } catch (error) {
      console.log(error)
      message.error(`Error al actualizar Servicio`)
    }
    setUpdateLoading(false)
  }

  const onSubCategorySelect = (value: string) => {
    setForm(prevState => {
      const copy = { ...prevState }
      copy.subCategoryId = value
      return copy
    })
  }

  const setSepecialty = (value: any) => {
    setForm(prevState => {
      const copy = { ...prevState }
      copy.specialtyId = value
      return copy
    })
  }

  const onLocalFormChange = (text: any) => {
    const { name, value } = text.target
    setForm(prevState => {
      const copy = { ...prevState }
      copy[name] = value
      return copy
    })
  }

  const renderSaveButton = () => {
    return (
      <div className={styles.save}>
        <Button
          onClick={onUpdateService}
          loading={updateLoading}
          style={{ ...buttonStyle }}>
          ACTUALIZAR SERVICIO
        </Button>
        <Remove serviceId={serviceId} />
      </div>
    )
  }

  const renderFormData = () => (
    <div className={styles.data}>
      <div className={styles.dataForm}>
        {fields.map((field, idx) => (
          <div className={styles.field} key={idx.toString()}>
            <div className={styles.fLabel}>{field.label}</div>
            <Input
              name={field.name}
              style={{ borderRadius: 30, height: 50 }}
              value={form[field.name]}
              placeholder={field.placeholder}
              onChange={onLocalFormChange}
            />
          </div>
        ))}
      </div>
      <div className={styles.dField}>
        <div className={styles.label}>Detalle</div>
        <TextArea
          name="details"
          value={form.details}
          style={{ borderRadius: 30 }}
          placeholder="Escribe el detalle del servicio que se llevará a cabo"
          autoSize={{ minRows: 4, maxRows: 5 }}
          onChange={onLocalFormChange}
        />
      </div>
    </div>
  )

  const selectSubCategory = () => {
    const options = form.specialtyId
      ? subCategories[form.specialtyId] || []
      : []
    return (
      <div className={styles.subCategory}>
        <div className={styles.hLabel}>Selecciona una subcategoria</div>
        <Select
          onChange={onSubCategorySelect}
          defaultValue={form.subCategoryId}
          placeholder="Selecciona una subcategoría"
          style={{ width: '30%' }}>
          {options.map((subCategory: OptionTypes, idx: number) => {
            return (
              <Option key={idx.toString()} value={subCategory.value}>
                {subCategory.label}
              </Option>
            )
          })}
        </Select>
        <SubCategory specialties={specialties} refetchSpecialties={refetch} />
      </div>
    )
  }

  const renderSpecialties = () => {
    return (
      <div className={styles.specialties}>
        <div className={styles.label}>Selecciona la especialización</div>
        <div className={styles.specialtiesContent}>
          {specialties.map((specialty, idx) => {
            const isActive = specialty.value === form.specialtyId
            return (
              <div
                className={
                  isActive ? styles.selectedSpecialty : styles.specialty
                }
                key={idx.toString()}
                onClick={() => setSepecialty(specialty.value)}>
                {specialty.label}
              </div>
            )
          })}
        </div>
      </div>
    )
  }

  const renderForm = () => {
    return (
      <div className={styles.form}>
        {renderSpecialties()}
        {selectSubCategory()}
        {renderFormData()}
        {renderSaveButton()}
      </div>
    )
  }

  if (specialtyLoading || serviceLoading || isEmpty(form)) return <Loading />
  return (
    <div className={styles.container}>
      <BackArrow path="/services" />
      <TitleHeader label="editar servicio" />
      {renderForm()}
    </div>
  )
}

export default Update
