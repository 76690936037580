import React from 'react'
import styles from './styles.module.css'
import TitleHeader from '@src/components/titleHeader'
import { header } from './data'
import { Button } from 'antd'
import { navigate } from 'gatsby'
import { useQuery } from '@apollo/client'
import Loading from '@src/components/loading'
import { CANCELED_MEETINGS } from '@src/helpers/queries/meetings.gql'
import { Table } from 'antd'
import dayjs from 'dayjs'

interface QueryTypes {
  allCancelMeetings: {
    id: string
    serviceDetails: {
      serviceName: string
      price: number
    }
    metadata: {
      date: Date
    }
    user: {
      auth0Data: {
        name: string
      }
    }
    totalAmount: number
  }
}

const CancelMeetings: React.FC = () => {
  const { loading, error, data: meetingsData } = useQuery<QueryTypes>(
    CANCELED_MEETINGS,
    {
      variables: {},
    },
  )

  const items =
    meetingsData?.allCancelMeetings?.map((meeting, index) => {
      return {
        key: index,
        services: meeting.serviceDetails,
        client: `${meeting?.user.auth0Data?.name} - ${meeting?.user?.auth0Data?.email}`,
        totalAmount: meeting.totalAmount,
        date: dayjs(meeting.metadata.date).format('MMMM D, YYYY'),
      }
    }) || []

  const renderHeader = () => {
    return (
      <div className={styles.header}>
        <TitleHeader label="horas agendadas canceladas" />
      </div>
    )
  }

  const renderTable = () => {
    if (loading) return <Loading />
    return (
      <div className={styles.table}>
        <Table columns={header} dataSource={items} />
      </div>
    )
  }

  return (
    <div className={styles.container}>
      {renderHeader()}
      {renderTable()}
      <div className={styles.button}>
        <Button
          type="primary"
          shape="round"
          onClick={() => navigate('/payments')}>
          Pagos
        </Button>
      </div>
    </div>
  )
}

export default CancelMeetings
