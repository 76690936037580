import * as React from 'react'
import Calification from '@src/components/calification'
import { navigate } from 'gatsby'
import styles from './styles.module.css'
import rutParser from '@src/helpers/rutParser'

type ProfessionalTypes = {
  key: number
  professionalId: string
  name: string
  rut: string
  email: string
  phone: string
  rating: number
}

export const header = [
  {
    title: 'Nombre',
    dataIndex: 'name',
    key: 'name',
    render: (text: string, record: ProfessionalTypes) => {
      const style =
        record.key % 2
          ? { background: '#fff', color: '#221F1F' }
          : { background: 'rgba(245, 213, 242, 0.4)', color: '#221F1F' }
      return {
        props: {
          style,
        },
        children: (
          <div
            className={styles.headerName}
            onClick={() => navigate(`/professionals/${record.professionalId}`)}
            style={{ fontSize: 15 }}>
            {text}
          </div>
        ),
      }
    },
  },
  {
    title: 'RUT',
    dataIndex: 'rut',
    key: 'rut',
    render: (text: string, record: ProfessionalTypes) => {
      const style =
        record.key % 2
          ? { background: '#fff', color: '#221F1F' }
          : { background: 'rgba(245, 213, 242, 0.4)', color: '#221F1F' }
      return {
        props: {
          style,
        },
        children: <div style={{ fontSize: 15 }}>{rutParser(text)}</div>,
      }
    },
  },
  {
    title: 'Email',
    dataIndex: 'email',
    key: 'email',
    render: (text: string, record: ProfessionalTypes) => {
      const style =
        record.key % 2
          ? { background: '#fff', color: '#221F1F' }
          : { background: 'rgba(245, 213, 242, 0.4)', color: '#221F1F' }
      return {
        props: {
          style,
        },
        children: <div style={{ fontSize: 15 }}>{text}</div>,
      }
    },
  },
  {
    title: 'Teléfono',
    dataIndex: 'phone',
    key: 'phone',
    render: (text: string, record: ProfessionalTypes) => {
      const style =
        record.key % 2
          ? { background: '#fff', color: '#221F1F' }
          : { background: 'rgba(245, 213, 242, 0.4)', color: '#221F1F' }
      return {
        props: {
          style,
        },
        children: <div style={{ fontSize: 15 }}>{text}</div>,
      }
    },
  },
  {
    title: 'Calificación',
    dataIndex: 'rating',
    key: 'rating',
    render: (text: string, record: ProfessionalTypes) => {
      const style =
        record.key % 2
          ? { background: '#fff', color: '#221F1F' }
          : { background: 'rgba(245, 213, 242, 0.4)', color: '#221F1F' }
      return {
        props: {
          style,
        },
        children: <Calification value={parseInt(text)} />,
      }
    },
  },
]
