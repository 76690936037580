import React, { useState } from 'react'
import { Button, Modal, message } from 'antd'
import { CloseOutlined } from '@ant-design/icons'
import { useMutation } from '@apollo/client'
import styles from './styles.module.css'
import { DELETE_SERVICE } from '@src/helpers/mutations/services.gql'
import { DeleteServiceTypes } from './mutationTypes'
import { navigate } from 'gatsby'
import { deleteButtonStyle } from '../customStyles'

type RemoveServiceProps = {
  serviceId: string
}

const RemoveService: React.FC<RemoveServiceProps> = ({ serviceId }) => {
  const [show, setShow] = useState(false)
  const [loading, setLoading] = useState(false)
  const [deleteService] = useMutation<DeleteServiceTypes>(DELETE_SERVICE)

  const onShow = () => setShow(true)

  const onCancel = () => setShow(false)

  const onConfirm = async () => {
    setLoading(true)
    try {
      await deleteService({ variables: { id: serviceId } })
      message.success('Servicio eliminado')
      navigate('/services')
    } catch (error) {
      console.log(error)
      message.error('Ocurrió un error al eliminar el servicio')
    }
    setLoading(false)
  }

  const renderButtons = () => (
    <div className={styles.cancelButton}>
      <Button key="back" onClick={onCancel}>
        Salir
      </Button>
      <Button
        key="submit"
        type="primary"
        danger
        loading={loading}
        onClick={onConfirm}>
        Confirmar
      </Button>
    </div>
  )

  return (
    <>
      <Button
        style={deleteButtonStyle}
        type="primary"
        shape="round"
        danger
        block
        icon={<CloseOutlined />}
        onClick={onShow}>
        ELIMINAR
      </Button>
      <Modal
        visible={show}
        title="¿Deseas eliminar este servicio?"
        onOk={onConfirm}
        onCancel={onCancel}
        footer={renderButtons()}>
        <div>
          Se eliminará este servicio y su relación con aquellos profesionales
          que lo tengan
        </div>
      </Modal>
    </>
  )
}

export default RemoveService
