import * as React from 'react'
import rutParser from '@src/helpers/rutParser'
import styles from './styles.module.css'
import numeral from 'numeral'
import { navigate } from 'gatsby'
import dayjs from 'dayjs'

type ProfessionalsTypes = {
  id: string
  key: number
  name: string
  rut: string
  payment: number
  dateFrom: string
  dateTo: string
}

export const header = [
  {
    title: 'Profesional',
    dataIndex: 'name',
    key: 'name',
    render: (text: string, record: ProfessionalsTypes) => {
      const style =
        record.key % 2
          ? { background: '#fff', color: '#221F1F' }
          : { background: 'rgba(245, 213, 242, 0.4)', color: '#221F1F' }
      return {
        props: {
          style,
        },
        children: (
          <div
            className={styles.listSelection}
            onClick={() =>
              navigate(
                `/payments/${record.id}/${record.dateFrom}/${
                  record.dateTo || dayjs().format('MM-DD-YY')
                }`,
              )
            }>
            {text}
          </div>
        ),
      }
    },
  },
  {
    title: 'RUT',
    dataIndex: 'rut',
    key: 'rut',
    render: (text: string, record: ProfessionalsTypes) => {
      const style =
        record.key % 2
          ? { background: '#fff', color: '#221F1F' }
          : { background: 'rgba(245, 213, 242, 0.4)', color: '#221F1F' }
      return {
        props: {
          style,
        },
        children: <div style={{ fontSize: 15 }}>{rutParser(text)}</div>,
      }
    },
  },
  {
    title: 'Monto a pagar',
    dataIndex: 'payment',
    key: 'payment',
    render: (text: string, record: ProfessionalsTypes) => {
      const style =
        record.key % 2
          ? { background: '#fff', color: '#221F1F' }
          : { background: 'rgba(245, 213, 242, 0.4)', color: '#221F1F' }
      return {
        props: {
          style,
        },
        children: (
          <div style={{ fontSize: 15 }}>{numeral(text).format('$ 0,0')}</div>
        ),
      }
    },
  },
]
