import React, { useState, useEffect } from 'react'
import styles from './styles.module.css'
import { Table, Input } from 'antd'
import { SearchOutlined } from '@ant-design/icons'
import Loading from '@src/components/loading'
import { useLazyQuery } from '@apollo/client'
import searchFilter from './searchFilter'
import { header } from './data'
import { isEmpty } from 'ramda'
import { Button } from 'antd'
import { customInput } from './customStyles'
import DateFilter from './date-filter'
import { queryFilter } from './date-filter/helpers'
import TitleHeader from '@src/components/titleHeader'
import { PROFESSIONAL_PAYMENT_LIST } from '@src/helpers/queries/professionals.gql'
import { ProfessionalsInventory, QueryInput } from './types'
import dayjs from 'dayjs'
import { navigate } from 'gatsby'

type DatesSelectedTypes = {
  dateFrom?: Date
  dateTo?: Date
}

type DateTypes = {
  dateFrom: Date
  dateTo: Date
}

type LocalProfessionalsTypes = {
  id: string
  key: number
  name: string
  rut: string
  payment: number
  dateFrom: string
  dateTo: string
}

const Payments: React.FC = () => {
  const [filter, setFilter] = useState<string>(null)
  const [items, setItems] = useState<LocalProfessionalsTypes[]>([])
  const [professionals, setProfessionals] = useState<LocalProfessionalsTypes[]>(
    [],
  )
  const [dateFilter, setDateFilter] = useState<DateTypes>({})
  const [datesSelected, setDatesSelected] = useState<DatesSelectedTypes>({})
  const [getProfessionals, { loading, data }] = useLazyQuery<
    ProfessionalsInventory,
    QueryInput
  >(PROFESSIONAL_PAYMENT_LIST, {
    notifyOnNetworkStatusChange: true,
  })

  useEffect(() => {
    const { dateFrom, dateTo } = dateFilter
    getProfessionals({ variables: { dateFrom, dateTo } })
  }, [])

  useEffect(() => {
    const { dateFrom, dateTo } = dateFilter
    if (queryFilter(datesSelected, dateFilter)) {
      getProfessionals({ variables: { dateFrom, dateTo } })
      setDatesSelected({ dateFrom, dateTo })
    }
  }, [dateFilter])

  useEffect(() => {
    const addProfessionals = () => {
      if (data && data.professionalPaymentInterval) {
        const { dateFrom, dateTo } = dateFilter
        const parseData = []
        let idx = 1

        for (const professional of data.professionalPaymentInterval) {
          if (!professional.intervalPaymentDetail.professionalPayment) continue
          idx++
          parseData.push({
            key: idx,
            id: professional.id,
            name: professional.name,
            rut: professional.rut,
            payment: professional.intervalPaymentDetail.professionalPayment,
            dateFrom: dayjs(dateFrom).format('MM-DD-YY'),
            dateTo: dayjs(dateTo).format('MM-DD-YY'),
          })
        }
        setProfessionals(parseData)
      }
    }

    addProfessionals()
  }, [data])

  useEffect(() => {
    const setTableItems = () => {
      if (!filter) setItems(professionals)
      else {
        const newItems = searchFilter(filter, professionals)
        setItems(newItems)
      }
    }

    setTableItems()
  }, [filter, professionals])

  const onDateFilterChange = (value: Date, name: string) => {
    setDateFilter(prevState => {
      const copy = { ...prevState }
      copy[name] = value
      return copy
    })
  }

  const onTextChange = (text: { target: { value: string } }) => {
    if (isEmpty(text.target.value)) setFilter(null)
    else setFilter(text.target.value)
  }

  const renderHeader = () => {
    return (
      <div className={styles.header}>
        <div className={styles.logoContent}>
          <TitleHeader label="pagos" />
        </div>
        <div className={styles.inputs}>
          <Input
            style={{ ...customInput }}
            placeholder="Buscar"
            prefix={<SearchOutlined />}
            onChange={onTextChange}
          />
        </div>
      </div>
    )
  }

  const renderTable = () => {
    if (loading) return <Loading />
    return <Table columns={header} dataSource={items} />
  }

  return (
    <div className={styles.container}>
      {renderHeader()}
      <DateFilter
        items={items}
        onDateFilterChange={onDateFilterChange}
        dateFilter={dateFilter}
        datesSelected={datesSelected}
      />
      {renderTable()}
      <div className={styles.button}>
        <Button
          type="danger"
          shape="round"
          onClick={() => navigate('/cancel-meetings')}>
          Servicios cancelados
        </Button>
      </div>
    </div>
  )
}

export default Payments
