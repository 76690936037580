import React, { useState, useEffect } from 'react'
import styles from './styles.module.css'
import { Input, message, Button, Select, Spin } from 'antd'
import fields from './fields'
import { CREATE_SERVICE } from '@src/helpers/mutations/services.gql'
import { useMutation, useQuery } from '@apollo/client'
import { buttonStyle } from './customStyles'
import { CreateServiceTypes } from './mutationTypes'
import { ALL_SUBCATEGORIES } from '@src/helpers/queries/subCategories.gql'

const { TextArea } = Input
const { Option } = Select

interface allSubCategories {
  id: string
  name: string
  specialtyId: string
}

interface SubCategoriesIntentory {
  allSubCategories: allSubCategories[]
}

type OptionTypes = {
  label: string
  value: string
}

type CreateServiceProps = {
  allSpecialties: {
    id: string
    name: string
    description: string
  }[]
  specialtiesLoading: boolean
  specialtiesError: object
  servicesRefetch: Function
}

type FormTypes = {
  title: string
  specialtyId: string
  price: string
  subCategoryId: string
  duration: string
  details: string
}

const CreateService: React.FC<CreateServiceProps> = ({
  allSpecialties,
  specialtiesLoading,
  specialtiesError,
  servicesRefetch,
}) => {
  const [form, setForm] = useState<FormTypes>({})
  const [createLoading, setCreateLoading] = useState<boolean>(false)
  const [specialties, setSpecialties] = useState<OptionTypes[]>([])
  const [subCategories, setSubCategories] = useState<object>({})

  const {
    loading: subLoading,
    error: subError,
    data: subCategoriesData,
    refetch,
  } = useQuery<SubCategoriesIntentory>(ALL_SUBCATEGORIES)
  const [createService] = useMutation<CreateServiceTypes>(CREATE_SERVICE)

  useEffect(() => {
    if (specialtiesLoading || specialtiesError) return
    const newData = allSpecialties.map(specialty => {
      return {
        value: specialty.id,
        label: specialty.name,
      }
    })
    setSpecialties(newData)
  }, [allSpecialties])

  const clearState = () => {
    setForm({
      specialtyId: null,
      title: null,
      price: null,
      subCategoryId: null,
      duration: null,
      details: null,
    })
  }

  useEffect(() => {
    if (subCategoriesData && subCategoriesData.allSubCategories) {
      const newData = {}
      for (const sub of subCategoriesData.allSubCategories) {
        if (newData[sub.specialtyId]) {
          newData[sub.specialtyId].push({
            value: sub.id,
            label: sub.name,
          })
        } else {
          newData[sub.specialtyId] = [
            {
              value: sub.id,
              label: sub.name,
            },
          ]
        }
      }
      setSubCategories(newData)
    }
  }, [subCategoriesData])

  const onCreateService = async () => {
    if (!form.subCategoryId) {
      message.error(`Debes seleccionar una subcategoria`)
      return
    }

    setCreateLoading(true)
    try {
      await createService({
        variables: {
          input: {
            ...form,
            price: parseInt(form.price),
            duration: parseInt(form.duration),
          },
        },
      })
      servicesRefetch()
      clearState()
      message.success('Servicio creado con exito')
    } catch (error) {
      console.log(error)
      message.error(`Error al crear Servicio`)
    }
    setCreateLoading(false)
  }

  const onSubCategorySelect = (value: string) => {
    setForm(prevState => {
      const copy = { ...prevState }
      copy.subCategoryId = value
      return copy
    })
  }

  const onCategoryChange = value => {
    onLocalFormChange('specialtyId', value)
    onLocalFormChange('subCategoryId', null)
  }

  const onLocalFormChange = (name: string, value: string) => {
    setForm(prevState => {
      const copy = { ...prevState }
      copy[name] = value
      return copy
    })
  }

  const renderSaveButton = () => {
    return (
      <div className={styles.save}>
        <Button
          onClick={() => onCreateService()}
          loading={createLoading}
          style={{ ...buttonStyle }}>
          INGRESAR SERVICIO
        </Button>
      </div>
    )
  }

  const renderFormData = () => (
    <div className={styles.data}>
      <div className={styles.dataForm}>
        {fields.map((field, idx) => (
          <div className={styles.field} key={idx.toString()}>
            <div className={styles.fLabel}>{field.label}</div>
            <Input
              style={{ borderRadius: 30, height: 50 }}
              value={form[field.name]}
              placeholder={field.placeholder}
              onChange={text =>
                onLocalFormChange(field.name, text.target.value)
              }
            />
          </div>
        ))}
      </div>
      <div className={styles.dField}>
        <div className={styles.label}>Detalle</div>
        <TextArea
          value={form.details}
          style={{ borderRadius: 30 }}
          placeholder="Escribe el detalle del servicio que se llevará a cabo"
          autoSize={{ minRows: 4, maxRows: 5 }}
          onChange={text => onLocalFormChange('details', text.target.value)}
        />
      </div>
    </div>
  )

  const selectSubCategory = () => {
    const options = form.specialtyId
      ? subCategories[form.specialtyId] || []
      : []
    return (
      <div className={styles.subCategory}>
        <div className={styles.hLabel}>Selecciona una subCategoría</div>
        <Select
          value={form.subCategoryId}
          onChange={onSubCategorySelect}
          placeholder="Selecciona una subcategoría"
          style={{ width: '30%' }}>
          {options.map((subCategory: OptionTypes, idx: number) => {
            return (
              <Option key={idx.toString()} value={subCategory.value}>
                {subCategory.label}
              </Option>
            )
          })}
        </Select>
      </div>
    )
  }

  const renderSpecialties = () => {
    return (
      <div className={styles.specialties}>
        <div className={styles.label}>Selecciona la especialización</div>
        <div className={styles.specialtiesContent}>
          {specialties.map((specialty, idx) => {
            const isActive = specialty.value === form.specialtyId
            return (
              <div
                className={
                  isActive ? styles.selectedSpecialty : styles.specialty
                }
                key={idx.toString()}
                onClick={() => onCategoryChange(specialty.value)}>
                {specialty.label}
              </div>
            )
          })}
        </div>
      </div>
    )
  }

  const renderForm = () => {
    return (
      <div className={styles.form}>
        {renderSpecialties()}
        {selectSubCategory()}
        {renderFormData()}
        {renderSaveButton()}
      </div>
    )
  }

  const renderHeader = () => {
    return (
      <div className={styles.header}>
        <div className={styles.title}>Servicios</div>
        <div className={styles.subTitle}>Crear servicio</div>
      </div>
    )
  }

  if (specialtiesLoading || subLoading) return <Spin size="large" />
  return (
    <div className={styles.container}>
      {renderHeader()}
      {renderForm()}
    </div>
  )
}

export default CreateService
