import * as React from 'react'
import { navigate } from 'gatsby'
import styles from './styles.module.css'
import numeral from 'numeral'

type ServicesTypes = {
  id: string
  key: number
  specialtyId: string
  title: string
  price: number
  duration: number
  details: string
  specialtyName: string
}

export const header = [
  {
    title: 'Especialidad',
    dataIndex: 'specialtyName',
    key: 'specialtyName',
    render: (text: string, record: ServicesTypes) => {
      const style =
        record.key % 2
          ? { background: '#fff', color: '#221F1F' }
          : { background: 'rgba(245, 213, 242, 0.4)', color: '#221F1F' }
      return {
        props: {
          style,
        },
        children: (
          <div>
            <div
              className={styles.headerName}
              onClick={() => navigate(`/services/${record.id}/update`)}
              style={{ backgroundColor: '#ce2ebc', fontSize: 15 }}>
              {text}
            </div>
          </div>
        ),
      }
    },
  },
  {
    title: 'Nombre',
    dataIndex: 'title',
    key: 'title',
    render: (text: string, record: ServicesTypes) => {
      const style =
        record.key % 2
          ? { background: '#fff', color: '#221F1F' }
          : { background: 'rgba(245, 213, 242, 0.4)', color: '#221F1F' }
      return {
        props: {
          style,
        },
        children: <div style={{ fontSize: 15 }}>{text}</div>,
      }
    },
  },
  {
    title: 'Tiempo',
    dataIndex: 'duration',
    key: 'duration',
    render: (text: string, record: ServicesTypes) => {
      const style =
        record.key % 2
          ? { background: '#fff', color: '#221F1F' }
          : { background: 'rgba(245, 213, 242, 0.4)', color: '#221F1F' }
      return {
        props: {
          style,
        },
        children: <div style={{ fontSize: 15 }}>{text} min</div>,
      }
    },
  },
  {
    title: 'Precio',
    dataIndex: 'price',
    key: 'price',
    render: (text: string, record: ServicesTypes) => {
      const style =
        record.key % 2
          ? { background: '#fff', color: '#221F1F' }
          : { background: 'rgba(245, 213, 242, 0.4)', color: '#221F1F' }
      return {
        props: {
          style,
        },
        children: (
          <div style={{ fontSize: 15 }}>{numeral(text).format('$0,0')}</div>
        ),
      }
    },
  },
  {
    title: 'Detalle',
    dataIndex: 'details',
    key: 'details',
    render: (text: string, record: ServicesTypes) => {
      const style =
        record.key % 2
          ? { background: '#fff', color: '#221F1F' }
          : { background: 'rgba(245, 213, 242, 0.4)', color: '#221F1F' }
      return {
        props: {
          style,
        },
        children: <div>{text}</div>,
      }
    },
  },
]
