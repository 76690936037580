import * as React from 'react'
import { Router } from '@reach/router'

import PrivateRoute from '@src/components/privateRoute'
import Professionals from '@src/components/pages/professionals/list'
import CreateProfessional from '@src/components/pages/professionals/create'
import ProfessionalProfile from '@src/components/pages/professionals/profile'
import UpdateProfessional from '@src/components/pages/professionals/update'
import CreateService from '@src/components/pages/services/create'
import UsersList from '@src/components/pages/users/list'
import UpdateService from '@src/components/pages/services/update'
import Services from '@src/components/pages/services/list'
import Payments from '@src/components/pages/payments/list'
import ProfessionalPayment from '@src/components/pages/payments/professional'
import CancelMeetings from '@src/components/pages/cancel-meetings'

import './index.less'
import { isAuthenticated, login } from '@src/utils/auth'

const App: React.FC = () => {
  if (!isAuthenticated()) {
    login()
    return <p>Redirecting...</p>
  }

  return (
    <Router>
      <PrivateRoute path="/" component={null} />
      <PrivateRoute path="/professionals" component={Professionals} />
      <PrivateRoute
        path="/professionals/create"
        component={CreateProfessional}
      />
      <PrivateRoute
        path="/professionals/:professionalId"
        component={ProfessionalProfile}
      />
      <PrivateRoute
        path="/professionals/:professionalId/update"
        component={UpdateProfessional}
      />
      <PrivateRoute path="/services" component={Services} />
      <PrivateRoute path="/services/create" component={CreateService} />
      <PrivateRoute
        path="/services/:serviceId/update"
        component={UpdateService}
      />
      <PrivateRoute path="/payments" component={Payments} />
      <PrivateRoute
        path="/payments/:professionalId/:dateFrom/:dateTo"
        component={ProfessionalPayment}
      />
      <PrivateRoute path="/cancel-meetings" component={CancelMeetings} />
      <PrivateRoute path="/users" component={UsersList} />
    </Router>
  )
}

export default App
