import React from 'react'
import styles from './styles.module.css'
import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'
import { navigate } from 'gatsby'

type ImageProps = {
  path: string
}

const Image: React.FC<ImageProps> = ({ path }) => {
  const data = useStaticQuery(graphql`
    query {
      placeholderImage: file(relativePath: { eq: "back-arrow.png" }) {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <div onClick={() => navigate(path)}>
      <Img
        className={styles.image}
        fluid={data.placeholderImage.childImageSharp.fluid}
      />
    </div>
  )
}

export default Image
