type ProfessionalTypes = {
  id: string
  name: string
  email: string
  rut: string
  title: string
  phone: string
  picture: {
    name: string
    uid: string
    url: string
    status: string
  }
  bankData: {
    bank: string
    accountType: string
    accountNumber: string
  }
}

export default (professional: ProfessionalTypes) => {
  return {
    id: professional.id,
    name: professional.name,
    email: professional.email,
    rut: professional.rut,
    title: professional.title,
    phone: professional.phone,
    picture:
      professional.picture[0].url ||
      'https://menorpago.com.co/wp-content/uploads/2020/07/monitor-par-pc-dell-e2220h-215-pulgadas-vga-y-display-port.png',
    bankData: { ...professional.bankData },
  }
}
