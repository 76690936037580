import React from 'react'
import { navigate } from 'gatsby'

type PrivateRouteProps = {
  component: React.FC
  path: string
  location?: {
    pathname: string
  }
}

const PrivateRoute: React.FC<PrivateRouteProps> = ({
  component: Component,
  location,
  ...rest
}) => {
  if (location.pathname === '/' || location.pathname === '/authentication') {
    typeof window !== 'undefined' && navigate('/professionals')
    return null
  }

  return <Component {...rest} />
}

export default PrivateRoute
