import { gql } from '@apollo/client'

const IS_LOGGED_IN = gql`
  query IsUserLoggedIn {
    isLoggedIn @client
  }
`

const USER = gql`
  query User {
    user @client {
      id
      email
      accessToken
      isLoggedIn
    }
  }
`

export { IS_LOGGED_IN, USER }
