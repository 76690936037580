import React, { useState } from 'react'
import styles from './styles.module.css'
import { useMutation, from } from '@apollo/client'
import { DELETE_PROFESSIONAL } from '@src/helpers/mutations/professionals.gql'
import { BsTrash } from 'react-icons/bs'
import { BoxStyled } from './framerStyle'
import { buttonStyle, confirMationButtonStyle } from './customStyles'
import { DeleteMutation, DeleteProfessionalVars } from './mutationTypes'
import { navigate } from 'gatsby'

import { Button, message } from 'antd'

type DeleteProps = {
  professionalId: string
}

const Delete: React.FC<DeleteProps> = ({ professionalId }) => {
  const [loading, setLoading] = useState<boolean>(false)
  const [deleteRequest, setDeleteRequest] = useState<boolean>(false)
  const [deleteProfessional] = useMutation<{
    deleteProfessional: DeleteMutation
    id: DeleteProfessionalVars
  }>(DELETE_PROFESSIONAL)

  const onDeleteProfessional = async () => {
    if (!professionalId) return
    setLoading(true)
    try {
      const { data } = await deleteProfessional({
        variables: { id: professionalId },
      })
      if (data.deleteProfessional && data.deleteProfessional.id) {
        message.success('Profesional eliminado correctamente')
        navigate('/professionals')
      }
    } catch (error) {
      message.error('Ocurrio un error al eliminar el profesional')
    }
    setLoading(false)
  }

  const onDeleteRequest = () => setDeleteRequest(!deleteRequest)

  const renderConfirmation = () => {
    return (
      <BoxStyled animate={deleteRequest ? 'visible' : 'hidden'}>
        <div className={styles.removeMessage}>
          ¿Estás seguro que deseas eliminar a este usuario?
        </div>
        <Button
          style={{ ...confirMationButtonStyle }}
          icon={<BsTrash />}
          loading={loading}
          onClick={onDeleteProfessional}>
          Eliminar
        </Button>
      </BoxStyled>
    )
  }

  return (
    <div className={styles.container}>
      <Button
        style={{ ...buttonStyle }}
        loading={loading}
        onClick={onDeleteRequest}>
        ELIMINAR PROFESSIONAL
      </Button>
      {renderConfirmation()}
    </div>
  )
}

export default Delete
