export const buttonStyle = {
  height: 'auto',
  backgroundColor: '#221F1F',
  borderColor: '#221F1F',
  width: 250,
}

export const customInput = {
  borderRadius: 30,
  marginRight: 20,
  width: 250,
}
